import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import MatchLocation from './MatchLocation';
import MatchDate from './MatchDate';
import { useState, useEffect } from 'react';

function MatchFixtureEle({ game }) {
    const text = "Club Cricket of Chicago";
    let img_url = "";
    let opponentTeamName = "";
  
    if (game.teamOne !== 2240 && game.teamOne !== 2202 && game.teamOne !== 2186 ) {
        opponentTeamName = game.teamOneName;
        img_url = game.t1_logo_file_path;
    } else {
        opponentTeamName = game.teamTwoName;
        img_url = game.t2_logo_file_path;
    }
    if (img_url === '') {
        img_url = "/images/Placeholderlogo.svg";
    } else {
        img_url = "https://cricclubs.com" + img_url;
    }
    return (
        <div className="MXE_ele flex_grid">
            <div className="oppo_team">
                <div className="flex_grid">
                    <div className="oppo_vs_text">
                        <p className="white_color roboto-condensed-regular p4">Vs</p>
                    </div>
                    <div className="oppo_team_ico">
                        <img src={img_url} alt="Opponent Logo" />
                    </div>
                </div>
                <div className="oppo_team_name">
                    <p className="white_color roboto-condensed-regular p6">{opponentTeamName}</p>
                </div>
            </div>
            <MatchLocation className="flex_grid" logoWhite={true} >{game.location}</MatchLocation>
            <MatchDate className="" logoWhite={true} >{game.fixedFormatDate}</MatchDate>
        </div>  
    );
}

function MatchResultEle({ result }) {
    let result_status = result.result.includes("Club Cricket of Chicago") || result.result.includes("Club Cricket Of Chicago Seekers") || result.result.includes("Club Cricket Of Chicago Seekers");
    let status_color = result_status ? "rgb(110 198 170)" : "rgb(241 10 10)";

    return (
        <div className="MRE_ele flex_grid">
            <div className="main_teamInfo_parent">
                <div className="teamInfo_parent flex_grid">
                    <div className="team_info won_team_info flex_grid">
                        <div className="score_board">
                            <div className="runs_wickets_info">
                                <p className="roboto-condensed-bold p4">{result.t1total}/{result.t1wickets}</p>
                            </div>
                            <div className="overs_info">
                                <p className="roboto-condensed-regular p6">{Math.round((result.t1balls / 6) * 10) / 10}/{result.t1wickets}</p>
                            </div>
                        </div>
                        <div className="team_info_logo">
                            <img src={"https://cricclubs.com" + result.t1_logo_file_path} alt="" />
                        </div>
                    </div>
                    <div className="team_info lost_team_info flex_grid">
                        <div className="team_info_logo">
                            <img src={"https://cricclubs.com" + result.t2_logo_file_path} alt="" />
                        </div>
                        <div className="score_board">
                            <div className="runs_wickets_info">
                                <p className="roboto-condensed-bold p4">{result.t2total}/{result.t2wickets}</p>
                            </div>
                            <div className="overs_info">
                                <p className="roboto-condensed-regular p6">{Math.round((result.t2balls / 6) * 10) / 10}/{result.t2wickets}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="result_status">
                    <p className="roboto-condensed-regular p6" style={{ color: status_color }}>{result.result.replace("Forfeited. ", "")}</p>
                </div>
            </div>
            <MatchDate className="" logoWhite={true} >{result.matchDate}</MatchDate>
        </div>
    );
}

export default function NewFixturesAndResults({ fixtureCount, resultsCount, seriesTeamMappingArr }) {
    const [games, setGames] = useState([]);
    const [results, setResults] = useState([]);

    useEffect(() => {
        if (seriesTeamMappingArr) {
            fetchAllGamesAndResults(seriesTeamMappingArr.seriesId, seriesTeamMappingArr.teamId);
        }
    }, [seriesTeamMappingArr]);

    async function fetchGamesJSON(seriesId, teamId) {
        let url = `https://ccapi.cricclubs.com/CCAPI/match/getSchedule?clubId=63&seriesId=${seriesId}&teamId=${teamId}&limit=1000&X-Auth-Token=`;
        console.log("Games Url:"+url)
        const response = await fetch(`https://ccapi.cricclubs.com/CCAPI/match/getSchedule?clubId=63&seriesId=${seriesId}&teamId=${teamId}&limit=1000&X-Auth-Token=`);
        const my_games = await response.json();
        return my_games.data.fixtureList;
    }

    async function fetchResultsJSON(seriesId, teamId) {
        let url = `https://ccapi.cricclubs.com/CCAPI/match/getMatches?XAuthToken=&clubId=63&seriesId=${seriesId}&teamId=${teamId}&limit=1000`;
        console.log("Games Url:"+url)
        const response = await fetch(`https://ccapi.cricclubs.com/CCAPI/match/getMatches?XAuthToken=&clubId=63&seriesId=${seriesId}&teamId=${teamId}&limit=1000`);
        const matchesResults = await response.json();
        return matchesResults.data;
    }

    async function fetchAllGamesAndResults(seriesId, teamId) {
        const games = await fetchGamesJSON(seriesId, teamId);
        const results = await fetchResultsJSON(seriesId, teamId);

        // Filter and set state
        const filteredGames = games;
        const filteredResults = results

        setGames(filteredGames);
        setResults(filteredResults);
    }

    return (
        <div className="LT_gridEle LT_fixtures_results_tabs_parent">
            <Tabs>
                <TabList>
                    <Tab className="roboto-condensed-bold react-tabs__tab p4 grey_text">Fixtures</Tab>
                    <Tab className="roboto-condensed-bold react-tabs__tab p4 grey_text">Results</Tab>
                </TabList>

                <TabPanel>
                    <div className="fixtures_container flex_grid">
                        {games.slice(0, fixtureCount).map((game) => (
                            <MatchFixtureEle key={game.fixtureId} game={game} />
                        ))}
                    </div>
                </TabPanel>
                <TabPanel>
                    {results.slice(0, resultsCount).map((result) => (
                        <MatchResultEle key={result.matchId} result={result} />
                    ))}
                </TabPanel>
            </Tabs>  
        </div>
    );
}
