import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CredsEle from "./CredsEle"
import { useEffect,useState } from 'react';

function ScoreCard({rank})
{
    
    let text="";
    let points=0;
    let main="";
    if(rank.battingPoints>rank.bowlingPoints)
    {
        text="Batting Points";
        main="Batsman";
        points = rank.battingPoints;
    }
    else 
    {
        text="Bowling Points";
        main="Bowler";
        points = rank.bowlingPoints;
    }
    

    return(
        <div className="SC_ele flex_grid">
            <div className="SC_ele_player_image">
                <img src={"https://cricclubs.com"+rank.profilepic_file_path} alt="" />
            </div>
            <div className="SC_ele_player_info flex_grid">
                <div className="SCE_playerInfo_cat">
                    <h5 className="roboto-condensed-regular p2 white_color" >{text}</h5>
                </div>
                <div className="SCE_playerInfo_score">
                    <p className="roboto-condensed-bold h4 brand_orange" >{points}</p>
                </div>
                <div className="SCE_playerInfo_name">
                    <p className="roboto-condensed-regular p2 white_color"  >{rank.firstName} {rank.lastName}</p>
                </div>
                <div className="SCE_playerInfo_main">
                    <p className="roboto-condensed-regular p5 white_color" >{main}</p>
                </div>
            </div>
        </div>
    )
}




export default function LeagueHighlights({battingInfo,bowlingInfo,RankingInfo,LeagueInfo})
{   
    
    const[battingStats,setBattingStats]=useState({
                                                    totalCenturies:0,
                                                    totalHalfCenturies:0,
                                                    topRuns:0,
                                                    runsFromBoundary:0,
                                                    totalFours:0,
                                                    totalSixes:0
                                                })

    const[bowlingStats,setBowlingStats]= useState({
                                                    totalWickets:0,
                                                    totalCatches:0,
                                                    totalFourWickets:0,
                                                    totalBalls:0,
                                                    totalDotBalls:0,
                                                    totalRunsAgainst:0
                                                });
    
    

    const [leagueData, setLeagueData] = useState({ 
                                                    totalMatches: 0,
                                                    totalRunsScored: 0,
                                                    totalRunsGiven:0,
                                                    totalWicketsTaken:0,
                                                    totalWicketsGiven:0
                                                    
                                                });


    // useEffect(()=>{FetchPlayerIds()},[RankingInfo]);
    useEffect(()=>{CalcBattingStats()},[battingInfo]);
    useEffect(()=>{CalcBowlingStats()},[bowlingInfo]);
    useEffect(()=>{CalcLeagueStats()},[LeagueInfo]);
    
  
    function CalcBattingStats()
    {
         // BATTING TAB VARIABLES
        let totalCenturies,totalHalfCenturies,topRuns,runsFromBoundary,totalFours,totalSixes;
        totalCenturies=totalHalfCenturies=topRuns=runsFromBoundary=totalFours=totalSixes=0;

        battingInfo.forEach((player)=>{
           
            
            totalCenturies = totalCenturies+player.hundreds;
            totalHalfCenturies = totalHalfCenturies+player.fifties;
            totalFours = totalFours+player.fours;
            totalSixes = totalSixes+player.sixers;

            if( player.highestScore > topRuns)
            {
                topRuns = player.highestScore;
            }
            
        }) 
        runsFromBoundary = totalFours*4;
        setBattingStats(ev=>({
            ...ev,
            totalCenturies:totalCenturies
        }));
        setBattingStats(ev=>({
            ...ev,
            totalHalfCenturies:totalHalfCenturies
        }));
        setBattingStats(ev=>({
            ...ev,
            topRuns:topRuns
        }));
        setBattingStats(ev=>({
            ...ev,
            runsFromBoundary:runsFromBoundary
        }));
        setBattingStats(ev=>({
            ...ev,
            totalFours:totalFours
        }));
        setBattingStats(ev=>({
            ...ev,
            totalSixes:totalSixes
        }));
    }


    function CalcBowlingStats()
    {
        let totalWickets,totalCatches,totalFourWickets,totalBalls,totalDotBalls,totalRunsAgainst;
        totalWickets=totalCatches=totalFourWickets=totalBalls=totalDotBalls=totalRunsAgainst=0;
        bowlingInfo.forEach((player)=>{
            
            totalWickets= totalWickets+player.wickets;
            totalCatches= totalCatches+player.catches;
            totalFourWickets= totalFourWickets+player.fourWickets;
            totalBalls= totalBalls+player.balls;
            totalDotBalls= totalDotBalls+player.dotBalls;
            totalRunsAgainst= totalRunsAgainst+player.runs;
        })
        
        setBowlingStats(existingValues=>({
            ...existingValues,
            totalRunsAgainst:totalRunsAgainst
        }))
        setBowlingStats(existingValues=>({
            ...existingValues,
            totalDotBalls:totalDotBalls
        }))
        setBowlingStats(existingValues=>({
            ...existingValues,
            totalBalls:totalBalls
        }))
        setBowlingStats(existingValues=>({
            ...existingValues,
            totalFourWickets:totalFourWickets
        }))
        setBowlingStats(existingValues=>({
            ...existingValues,
            totalCatches:totalCatches
        }))
        setBowlingStats(existingValues=>({
            ...existingValues,
            totalWickets:totalWickets
        }))


    }

    function CalcLeagueStats()
    {
        console.log("CALCULATING LEAGUE INFORMATION")
        console.log(LeagueInfo);
        const text = "Club Cricket of Chicago";
       
        let trs=0,rngv=0,twt=0,twg=0;
        LeagueInfo.forEach((game,index)=>{
            
            if(game.teamOne === 2240 || game.teamOne === 2202 ||  game.teamOne === 2186  )
            {
                trs = trs+game.t1_1total;
                rngv = rngv+game.t2_1total
                twt = twt+game.t1_1wickets;
                twg = twg+game.t2_1wickets;
            }
            else if( game.teamTwo === 2240 || game.teamTwo === 2202 || game.teamTwo === 2186)
            {
                trs = trs+game.t2_1total;
                rngv = rngv+game.t1_1total
                twt = twt+game.t2_1wickets;
                twg = twg+game.t1_1wickets;
            }

            
        })
       
        setLeagueData(existingValues=>({
            ...existingValues,
            totalWicketsGiven:twg
        }))
         setLeagueData(existingValues=>({
             ...existingValues,
             totalWicketsTaken:twt
         }))
         setLeagueData(existingValues=>({
            ...existingValues,
            totalRunsScored:trs
        }))
         setLeagueData(existingValues=>({
            ...existingValues,
            totalRunsGiven:rngv
        }))
        setLeagueData(existingValues => ({
            // Retain the existing values
            ...existingValues,
            // update the firstName
            totalMatches: LeagueInfo.length,
          }))
          
    }




   

   
    
    return(
            <section className="LH_container">
                <div className="LH_parent">
                    <Tabs>
                            <div className="tabList_parent">
                                <TabList>
                                    <Tab className="roboto-condensed-bold react-tabs__tab p4 grey_text" >League Stats</Tab>
                            
                                    <Tab className="roboto-condensed-bold react-tabs__tab p4 grey_text" >Top Player</Tab>
                                    <Tab className="roboto-condensed-bold react-tabs__tab p4 grey_text" >Team Batting</Tab>
                            
                                    <Tab className="roboto-condensed-bold react-tabs__tab p4 grey_text" >Team Bowling</Tab>
                                </TabList>
                            </div>
                            

                            <TabPanel className="react-tabs__tab-panel LS_parent" >
                                <CredsEle  setNum={leagueData.totalMatches} setText="Matches Played" />  
                                <CredsEle setNum={leagueData.totalRunsScored} setText="Runs Scored" />
                                <CredsEle setNum={leagueData.totalRunsGiven} setText="Runs Given" />

                                <CredsEle setNum={leagueData.totalWicketsTaken} setText="Wickets Taken" />  
                                <CredsEle setNum={leagueData.totalWicketsGiven} setText="Wickets Given" />
                                <CredsEle setNum={bowlingStats.totalBalls} setText="Total Balls" />
                            </TabPanel>

                            <TabPanel className="react-tabs__tab-panel TP_parent">
                               <section className='TPG_parent '>
                                 
                                    {
                                        RankingInfo.map((rank,index)=>
                                        {
                                            if(index<4)
                                            {
                                                return(
                                                    <ScoreCard key={index} rank={rank} />
                                                )
                                            }
                                        })
                                    }   
                                        
                                        
                                   
                                    
                                </section>
                            </TabPanel>

                            <TabPanel className="react-tabs__tab-panel LS_parent" >
                                
                                <CredsEle setNum={battingStats.topRuns} setText="Highest Score" />
                                <CredsEle setNum={battingStats.totalCenturies} setText="CENTURIES" />  
                                <CredsEle setNum={battingStats.totalHalfCenturies} setText="HALF CENTURIES" />
                                   

                                <CredsEle setNum={battingStats.runsFromBoundary} setText="RUNS FROM BOUNDARIES" />  
                                <CredsEle setNum={battingStats.totalFours} setText="FOURS" />
                                <CredsEle setNum={battingStats.totalSixes} setText="SIXES" />


                            </TabPanel>

                            <TabPanel className="react-tabs__tab-panel LS_parent" >
                            <CredsEle setNum={bowlingStats.totalWickets} setText="WICKETS" />  
                                <CredsEle setNum={bowlingStats.totalCatches} setText="CATCHES" />
                                <CredsEle setNum={bowlingStats.totalFourWickets} setText="FOUR WICKETS" />   

                                <CredsEle setNum={bowlingStats.totalBalls} setText="TOTAL BALLS" />  
                                <CredsEle setNum={bowlingStats.totalDotBalls} setText="DOT BALLS" />
                                <CredsEle setNum={bowlingStats.totalRunsAgainst} setText="RUNS SCORED AGAINST" />


                            </TabPanel>
                        </Tabs>  
                </div>    
            </section>
    )
   
}