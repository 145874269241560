import StaticLogoSlider from "./StaticLogoSlider"
import LeaguePositionStats from "./LeaguePositionStats";
import LeagueStaticStandings from "./LeagueStaticStandings";
import LeagueStaticResultsSlider from "./LeagueStaticResultsSlider";
import { Link } from "react-router-dom";



export default function LeagueStaticStatsContainer({leagueData})
{
    
    return(
        <section className="LSC_container base_paddings">
            <div className="LSC_parent max_content center_aligned flex_grid">
                <div className="LSC_static_columnOne">
                    <StaticLogoSlider leagueData={leagueData[0]} />
                </div>
                <div className="LSC_static_columnTwo flex_grid">
                    <LeaguePositionStats playerData={leagueData[0].positionStat[0]} /> 
                    <div className="LSC_moredetails_parent">
                            <Link to={leagueData[0].detailedUrl} target="_blank" className="no_underline">  
                            <div className="LSC_moredetails flex_grid">
                                            
                                        <img src="/images/cric_club_logo.svg" alt="" />
                                    
                                        <p className="roboto-condensed-med p1 brand_orange">DETAILED INFO</p>
                                    
                            </div>
                            </Link>
                    </div>
                    
                </div>
            </div>
            <div className="LSC_parent max_content center_aligned flex_grid">
                <div className="LSC_static_columnOne standings_column">
                    <LeagueStaticStandings standingList={leagueData[0].standings} />    
                </div>
                <div className="LSC_static_columnTwo ">
                   <LeagueStaticResultsSlider resultsList={leagueData[0].results} />
                </div>
            </div>
        </section>
    )
}