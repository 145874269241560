import { useEffect,useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
function InstaPost(data)
{
    
    return(
        
            <div className="instaEle" >
                <Link to={data.data.permalink} className="no_href"   target="_blank"  >
                <div className="instaImage">
                    <img src={data.data.media_url} alt="Instagram Post Image"></img>
                </div>
                <div className="instaCaption">
                    <p className="roboto-condensed-regular p3">{data.data.caption.slice(0,200)}...</p>
                    
                </div>
                </Link>
            </div>
    )
}



export default function LatestPosts()
{   
    const [posts, setPosts] = useState([]);
    
    useEffect(()=>{fetchPosts()},[]);

    async function fetchPostsJson() {
        
        
        try {
            const response = await fetch('https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption,permalink&access_token=IGQVJXWnFjZA1hTdGZAuWkxPcVM0MkFvcDNRNzF5OERDbDZA6dC10VktxTmNiZAVNnMDMxenkxeVNrdzFWNEFkam1EVjlfWjRpYWZAJdGMzbmc4TXptRWRwTUZA3b1F2bnIxWVN5S1dEemJn');
            const stats = await response.json();
           
        } catch (e) {
            console.error(e);
        } finally {
            console.log('We do cleanup here');
            
        }
      }
      
    
    


      

    function fetchPosts()
    {
        console.log("Am I called");
        
        fetchPostsJson().then(stats => {
            
            setPosts( stats.data);
            console.log(posts);
        })  
    
        

    }
      


    return(
        <section className="Insta_LT_container base_paddings">
            <div className="max_content center_aligned ISLTC_parent">
                {posts.map((post,index) => {
                        if( post.media_type == "CAROUSEL_ALBUM")
                        {
                            return(<InstaPost data={post} />)
                        }
                        
                })}
            </div>
            

        </section>
    )
}
