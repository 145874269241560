import SectionTitleEle from "./SectionTitleEle";
import TimeCounter from "./TimeCounter";
import MatchDisplayEle from "./MatchDisplayEle";
import { useState, useEffect } from 'react';

export default function UpcomingMatchPanel() {
    const [games, setGames] = useState([]);
    const [seriesTeamMappingArr, setSeriesTeamMappingArr] = useState([]);
    let matchDate, matchTime;

    useEffect(() => {
        fetchSeriesData();
    }, []);

    async function fetchSeriesData() {
        const seriesStats = await fetchAllSeriesJSON();
        const currentYearSeries = seriesStats.data.seriesList.filter((s) => s.year === new Date().getFullYear().toString());
    
        let seriesTeamMapping = [];
    
        for (const series of currentYearSeries) {
            const seriesId = series.seriesID;
            const teamIds = [2240, 2202, 2186]; // Example team IDs, replace with relevant ones
    
            const teamData = await fetchTeamData(seriesId);
            
            if (teamData.data.teamsList !== null) {
                teamData.data.teamsList.forEach(group => {
                    group.teams.forEach(team => {
                        if (teamIds.includes(team.teamID)) {
                            seriesTeamMapping.push({ seriesId, teamId: team.teamID });
                        }
                    });
                });
            }
        }

        setSeriesTeamMappingArr(seriesTeamMapping);
        if (seriesTeamMapping.length > 0) {
            fetchUpcomingGames(seriesTeamMapping);
        }
    }

    async function fetchAllSeriesJSON() {
        const response = await fetch('https://ccapi.cricclubs.com/CCAPI/series/getSeriesList?X-Auth-Token=&clubId=63&limit=');
        const seriesStats = await response.json();
        return seriesStats;
    }

    async function fetchTeamData(seriesId) {
        const response = await fetch(`https://ccapi.cricclubs.com/CCAPI/team/getTeamsList?clubId=63&seriesId=${seriesId}`);
        const teamData = await response.json();
        return teamData;
    }

    async function fetchGamesJSON(seriesId, teamId) {
        const response = await fetch(`https://ccapi.cricclubs.com/CCAPI/match/getSchedule?clubId=63&seriesId=${seriesId}&teamId=${teamId}&limit=100&X-Auth-Token=`);
        const my_games = await response.json();
        return my_games;
    }

    function fetchUpcomingGames(seriesTeamMapping) {
        let allGames = [];
        seriesTeamMapping.forEach(async ({ seriesId, teamId }) => {
            const gamesData = await fetchGamesJSON(seriesId, teamId);
            allGames = [...allGames, ...gamesData.data.fixtureList];
            setGames(allGames);
        });
    }

    return (
        <section className="UMP_container base_paddings">
            <div className="UMP_parent center_aligned max_content">
                <SectionTitleEle>Next Season Starts In</SectionTitleEle>
                <div className="UMP_parent_grid flex_grid">
                    {/* <div className="UMP_match_ele">
                        {games.slice(0, 1).map((game) => {
                            matchDate = game.fixedFormatDate;
                            matchTime = game.time;
                            return (
                                <MatchDisplayEle key={game.fixtureId} bgColor="#161616" game={game} />
                            );
                        })}
                    </div> */}
                    <div className="UMP_timer">
                        {/* <TimeCounter matchDate={matchDate} matchTime={matchTime} /> */}
                        <TimeCounter matchDate="2025-03-02" matchTime="12:00 PM" />
                    </div>
                </div>
            </div>
        </section>
    );
}
