
export default function PlayerOfTheWeek({batterInfo,bowlerInfo})
{
  
    if(bowlerInfo===undefined)
    {
        bowlerInfo=[];
    }
    if(batterInfo===undefined)
    {
        batterInfo=[];
    }
    return(
            <section className="POTW_container" >
                <div className="POTW_parent flex_grid">
                    <div className="POTW_container">
                            <div className="POTW_player_title">
                                    <h4 className=" brand_orange p1 roboto-condensed-bold">BEST BATSMAN</h4>
                            </div>
                            <div className="POTW_ele POTW_bestBatsman" style={{ backgroundImage: `url("https://cricclubs.com`+batterInfo.profilepic_file_path+`")` }}>
                            

                            </div>
                            <div className="POTW_text_overlay" > 
                                       
                                        <p className="POTW_score brand_blue h2 roboto-condensed-bold ">{batterInfo.runsScored}</p>
                                        <p className="POTW_score_text brand_blue p2 roboto-condensed-regular " >RUNS</p>
                                        <p className="POTW_playerName white_color p1 oswald-bold" >{batterInfo.firstName} {batterInfo.lastName}</p>
                            </div>
                    </div>
                    
                    <div className="POFT_container">
                        <img src="/images/ccc_players.svg" alt="" />
                    </div>
                    <div className="POTW_container">
                        <div className="POTW_player_title">
                            <h4 className=" brand_orange p1 roboto-condensed-bold">BEST BOWLER</h4>
                        </div>
                        <div className=" POTW_ele POTW_bestBowler" style={{ backgroundImage: `url("https://cricclubs.com`+bowlerInfo.profilepic_file_path+`")` }}>
                            
                        </div>
                        <div className="POTW_text_overlay" >
                                    
                                    <p className="POTW_score brand_orange h2 roboto-condensed-bold ">{bowlerInfo.wickets}</p>
                                    <p className="POTW_score_text brand_orange p2 roboto-condensed-regular " >WICKETS</p>
                                    <p className="POTW_playerName white_color p1 oswald-bold" >{bowlerInfo.firstName} {bowlerInfo.lastName}</p>
                        </div>
                    </div>
                </div>
            </section>
    )
}