import Footer from "./components/Footer";
import HeaderNavPanel from "./components/HeaderNavPanel";
import LeagueStaticStatsContainer from "./components/LeagueStaticStatsContainer";
import LogoGrid from "./components/LogoGrid";
import staticLeagueData from './data/bpl.json'
import { Helmet,HelmetProvider  } from "react-helmet-async"


export default function BPLLeague()
{
    return(
        <>
            <HelmetProvider>
                <Helmet>
                        <meta charSet="utf-8" />
                        <title>BPL 30/30Cup 2022 | CCC Stats and Standings</title>
                    
                        <meta name="description" content="Get access to the BPL 30/30Cup 2022 Stats and Standings for Club Cricket of Chicago." />
                        <meta property="og:url" content="/images/og-images/bpl_og_image.jpg" />
                        <meta name="keywords" content="Cricket"></meta>
                </Helmet>       
            </HelmetProvider>
            
            <HeaderNavPanel /> 
            <LeagueStaticStatsContainer  leagueData={staticLeagueData} />  
            <LogoGrid />
            <Footer />
        </>
    )
}