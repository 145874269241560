import MatchLocation from "./MatchLocation"
import MatchDate from "./MatchDate"

export default function MatchDisplayEle({bgColor,game})
{
    return(
        <div className="MDE_container" style={{ backgroundColor:bgColor}}>
            <MatchDate className="flex_grid" >{game.fixedFormatDate}</MatchDate>
            <div className="match_league">
                    <p className="roboto-condensed-regular p6" >{game.seriesName}</p>
            </div>
            <div className="match_vs_icos flex_grid">
                <div className="vs_ico vs_logo_1">
                    <img src={"https://cricclubs.com"+game.t1_logo_file_path} alt="" />
                </div>
                <div className="vs_content">
                    <p className="roboto-condensed-regular p4">VS</p>
                </div>
                <div className="vs_ico vs_logo_2">
                    <img src={"https://cricclubs.com"+game.t2_logo_file_path} alt="" />
                </div>
            </div>
            <MatchLocation className="flex_grid" logoWhite={false} >{game.location}</MatchLocation>
        </div>
    )
}