import MatchDate from "./MatchDate";

export default function MatchStaticResultEle({result})
{
   
    const text = "CCC Superkings";
    let status_color ="rgb(241 10 10)";
    if( result.t1_name === text && result.win_status === "t1")
    {
        status_color = "rgb(110 198 170)";
    }
    else if( result.t2_name === text && result.win_status === "t2")
    {
        status_color = "rgb(110 198 170)";
    }
    return(
            <div className="MRE_ele flex_grid" >
                
                <div className="main_teamInfo_parent">
                    
                    <div className="teamInfo_parent flex_grid">

                        <div className="team_info won_team_info flex_grid">
                            
                                <div className="score_board">
                                    <div className="runs_wickets_info">
                                        <p className="roboto-condensed-bold p1" >{result.t1_runs}/{result.t1_wickets}</p>
                                    </div>
                                    <div className="overs_info">
                                        <p className="roboto-condensed-regular p4" >{result.t1_overs}/{result.total_overs}</p>
                                    </div>
                                </div>

                                <div className="team_info_logo">
                                
                                    <img src={result.t1_icon_path} alt="" />
                                </div>
                           
                            
                        </div>

                        <div className="team_info lost_team_info flex_grid">
                            
                            <div className="team_info_logo">
                                 
                                <img src={result.t2_icon_path} alt="" />
                            </div>
                            
                            
                            <div className="score_board">
                                <div className="runs_wickets_info">
                                    <p className="roboto-condensed-bold p1" >{result.t2_runs}/{result.t2_wickets}</p>
                                </div>
                                <div className="overs_info">
                                    <p className="roboto-condensed-regular p4" >{result.t2_overs}/{result.total_overs}</p>
                                </div>
                            </div>

                        
                        </div>

                    </div>
                    

                    <div className="result_status">
                            <p className="roboto-condensed-regular p2" style={{color:status_color}}>{result.winHighlights}</p>
                    </div>

                </div>

                <MatchDate className="" logoWhite={true} >{result.date}</MatchDate>
            </div>
    )
}