
export default function MatchDate({className, logoWhite,children})
{
    let img_url = "/images/date.svg"
   
    const months = ["JANUARY","FEBURARY","MARCH","APRIL","MAY","JUNE","JULY","AUG","SEPT","OCT","NOV","DEC"];
    const days= ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];

    let date,month,year;
   
    let split_data = [];
    let day_val;

    if( children !== undefined && children.includes("-") )
    {
        split_data =  children.split("-");
        date= split_data[2];
        month = split_data[1];
        year = split_data[0];
        day_val= days[parseInt(date)];
       
    }
    else if( children !== undefined && children.includes("/") )
    {
        split_data =  children.split("/");
        date= split_data[1];
        month = split_data[0];
        year = split_data[2];
   
        day_val = "";
    }


    

   


    if( logoWhite === true)
    {
        img_url = "/images/date_white.svg"
    }

    return(
            <div className={`match_date ${className}`}  >
                    <div className="match_date_ico">
                        <img src={img_url} alt="Date Icon" />
                    </div>
                    <div className="match_date_info">
                        <p className="roboto-condensed-regular p6" >{day_val} <span className="p4">{date} {months[parseInt(month-1)]}</span> {year}</p>
                    </div>
                </div>
    )
}