import { Routes, Route } from 'react-router-dom';
import './App.css';
import Home from "./Home"
import MatchCalendar from "./MatchCalendar";
import JoinUs from "./JoinUs"; 
import MidWestLeague from "./MidWestLeague";
import Grounds from './Grounds';
import ComingSoonCCC from './ComingSoonCCC';
import NewSeriesLeague from './NewSeriesLeague';
import BPLLeague from './BPLLeague';
import CasoAmericaLeague from './CasoAmericaLeague';
import CasoNineSportsLeague from './CasoNineSportsLeague';
import AllPlayers from './AllPlayers';
function App() {
  return (
    
    <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/calendar" element={<MatchCalendar />} />
          <Route path="/join-us" element={<JoinUs />} />
          <Route path="/2023/midwest" element={<MidWestLeague/>} />
          <Route path="/grounds" element={<Grounds />} />
          <Route path="/ccc-academy" element={<ComingSoonCCC /> } />
          <Route path="/2024/series/" element={<NewSeriesLeague />} />
          <Route path="/2022/league/bpl-30-30-cup" element={<BPLLeague />} />
          <Route path="/2022/league/caso-mid-america-permier-league" element={<CasoAmericaLeague />} />
          <Route path="/2022/league/caso-nine-sports-fall-t20" element={<CasoNineSportsLeague />} />
          <Route path="/players" element={<AllPlayers />} />
        </Routes>
      
    </div>
  );
}

export default App;
