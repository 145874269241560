import HeaderNavPanel from "./components/HeaderNavPanel"
import HeroBanner from "./components/HeroBanner"
import UpcomingGames from "./components/UpcomingGames"
import Tournaments from "./components/Tournaments"
import NewSeasonCounter from "./components/NewSeasonCounter"
import LogoGrid from "./components/LogoGrid"
import LatestPosts from "./components/LatestPosts"
import MeetSquad from "./components/MeetSquad"
import BGParralaxBanner from "./components/BGParralaxBanner"
import Footer from "./components/Footer"
import './Site.css';
import playersData from './data/data.json'
import { Helmet,HelmetProvider  } from "react-helmet-async"

import { useEffect, useState } from "react"
export default function Home()
{
    
    const [renderComponentB, setRenderComponentB] = useState(false);

    useEffect(() => {
        setRenderComponentB(true);

        
      },[]);
    // refresh
    
    
    
    return(
        <>
            <HelmetProvider>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Club Cricket of Chicago | Discover Competitive Cricket</title>
                
                    <meta name="description" content="Welcome to Club Cricket of Chicago, your home for cricket in the Windy City. Join us and discover the joy of competitive cricket." />
                    <meta property="og:url" content="/images/og-images/home_og_image.jpg" />
                    <meta name="keywords" content="Cricket"></meta>
                </Helmet>
            </HelmetProvider>
            
            
             <HeaderNavPanel />
             <HeroBanner />
             <UpcomingGames />
             <Tournaments />
               <NewSeasonCounter />
             {renderComponentB && <MeetSquad playersData={playersData} />}
             <LatestPosts />
             <BGParralaxBanner />
             <LogoGrid />
             <Footer />
        </>
  
    )
}