import Calendar from 'react-calendar';
import { useState, useEffect } from 'react';
import MatchDisplayEle from './MatchDisplayEle';

function CaledarEle({ date, gamesLookUp, handleClick, notFoundClick }) {
    let groundLocation = '';
    let vsText = '';
    let img_url = '';
    let text = 'Club Cricket of Chicago';
    const today = new Date();
    let active_cls_name = "";
    let found_flag = false;
    let local_game = {};
    const [foundGame, setFoundGame] = useState({});

    useEffect(() => { FindGameData(); }, [gamesLookUp]);

    function FindGameData() {
        gamesLookUp.forEach(game => {
            let split_date_info = game.fixedFormatDate.split("-");
            let game_date = parseInt(split_date_info[2]);
            let game_month = parseInt(split_date_info[1]);
            let game_year = split_date_info[0];

            if (game_date === date.getDate() && game_month === date.getMonth() + 1 && game_year === date.getFullYear()) {
                local_game = game;
                setFoundGame(local_game);
                found_flag = true;
            }
        });
    }

    if (today.getDate() === date.getDate() && today.getMonth() === date.getMonth() && today.getFullYear() === date.getFullYear()) {
        active_cls_name += " calendar_ele_active";
    }

    if (found_flag) {
        active_cls_name += " foundMatchActive";
        groundLocation = foundGame.ground;
        vsText = 'Vs';
        img_url = (foundGame.teamOneName !== text) ? foundGame.t1_logo_file_path : foundGame.t2_logo_file_path;
    }

    const months = ["JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"];
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    return (
        <div className={"calendar_ele " + active_cls_name} onClick={() => { if (found_flag) { handleClick(foundGame) } else { notFoundClick() } }} >
            <img className="caledar_ele_logo desk_only" src={"https://cricclubs.com" + img_url} alt="" />
            <div className="calendar_ele_date desk_only">
                <p className="roboto-condensed-bold p4 white_color">{date.getDate()} <span className="desk_only">{months[date.getMonth()]}</span></p>
                <p className="roboto-condensed-bold p4 white_color desk_only">{days[date.getDay()]}</p>
            </div>
            <div className="calendar_ele_date mob_only" style={{ backgroundImage: `url(https://cricclubs.com${img_url})` }}>
                <p className="roboto-condensed-bold p4 white_color">{date.getDate()}</p>
            </div>
            <div className="calendar_ele_vsText desk_only">
                <p className="roboto-condensed-regular p4 white_color">{vsText}</p>
            </div>
            <div className="calendar_ele_location desk_only">
                <p className="roboto-condensed-regular p6"> {groundLocation}</p>
            </div>
        </div>
    );
}

export default function DateCalendar() {
    const [games, setGames] = useState([]);
    const [foundGameRow, setFoundGameRow] = useState([]);

    useEffect(() => { fetchSeriesData(); }, []);

    async function fetchSeriesData() {
        const seriesStats = await fetchAllSeriesJSON();
        const currentYearSeries = seriesStats.data.seriesList.filter((s) => parseInt(s.year) >= new Date().getFullYear() - 2); // Fetch for current and past two years

        let seriesTeamMapping = [];

        for (const series of currentYearSeries) {
            const seriesId = series.seriesID;
            const teamIds = [2240, 2202, 2186]; // Example team IDs, replace with relevant ones

            const teamData = await fetchTeamData(seriesId);

            if (teamData.data.teamsList !== null) {
                teamData.data.teamsList.forEach(group => {
                    group.teams.forEach(team => {
                        if (teamIds.includes(team.teamID)) {
                            seriesTeamMapping.push({ seriesId, teamId: team.teamID });
                        }
                    });
                });
            }
        }

        if (seriesTeamMapping.length > 0) {
            fetchGames(seriesTeamMapping);
        }
    }

    async function fetchAllSeriesJSON() {
        const response = await fetch('https://ccapi.cricclubs.com/CCAPI/series/getSeriesList?X-Auth-Token=&clubId=63&limit=500'); // Increase limit if needed
        const seriesStats = await response.json();
        return seriesStats;
    }

    async function fetchTeamData(seriesId) {
        const response = await fetch(`https://ccapi.cricclubs.com/CCAPI/team/getTeamsList?clubId=63&seriesId=${seriesId}`);
        const teamData = await response.json();
        return teamData;
    }

    async function fetchGamesJSON(seriesId, teamId) {
        const response = await fetch(`https://ccapi.cricclubs.com/CCAPI/match/getSchedule?clubId=63&seriesId=${seriesId}&teamId=${teamId}&limit=500&X-Auth-Token=`);
        const my_games = await response.json();
        return my_games;
    }

    function fetchGames(seriesTeamMapping) {
        let allGames = [];
        seriesTeamMapping.forEach(async ({ seriesId, teamId }) => {
            const gamesData = await fetchGamesJSON(seriesId, teamId);
            allGames = [...allGames, ...gamesData.data.fixtureList];
            allGames.sort((a, b) => new Date(a.fixedFormatDate) - new Date(b.fixedFormatDate)); // Sort by date
            setGames(allGames);
        });
    }

    function handleClick(foundGame) {
        if (!foundGame || Object.keys(foundGame).length === 0) {
            setFoundGameRow("NO MATCH FOUND");
        } else {
            const ele = <MatchDisplayEle key={foundGame.fixtureId} bgColor="#161616" game={foundGame} />;
            setFoundGameRow(ele);
        }
    }

    function notFoundClick() {
        const ele = <div className="no_game_container"><p className="roboto-condensed-regular white_color p2">NO GAME</p></div>;
        setFoundGameRow(ele);
    }

    return (
        <section className="calendar_container base_paddings">
            <div className="calendar_parent max_content center_aligned">
                <div className="match_showCase mob_only">
                    {foundGameRow}
                </div>
                <div className="calendar_holder">
                    <Calendar className="custom_calendar" tileClassName="customCale_ele" maxDate={new Date(2024, 12, 0)} minDate={new Date(2022, 1, 0)} tileContent={({ date, view }) => <CaledarEle gamesLookUp={games} date={date} handleClick={handleClick} notFoundClick={notFoundClick} />} />
                </div>
            </div>
        </section>
    );
}
