import { useState,useEffect } from "react";
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom";

export default function LogoContainer({setLink,setWidth, setUrl,setClassName})
{
    const navigate = useNavigate();
   
    function handleClick()
    {
        if(setLink.indexOf("https://milhouseinc.com/"))
        {
            window.open(setLink,"_self")
        }
        {
            navigate(setLink);
            window.location.reload(false);
        }
      
        
       
    }

    return(
        <div className={"logo_container "+setClassName} style={{ width:setWidth }} onClick={handleClick}  >
            
                <img src={"/images/"+setUrl}  alt="" />
            
        </div>

    )
}