import HeaderNavPanel from "./components/HeaderNavPanel";
import ComingSoonBGBanner from "./components/ComingSoonBGBanner";
import LogoGrid from "./components/LogoGrid";
import Footer from "./components/Footer";
import { Helmet,HelmetProvider  } from "react-helmet-async"

export default function ComingSoonCCC()
{
    return(
            <>
                <HelmetProvider>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>CCC Academy | Hone Your Cricket Skills </title>
                    
                        <meta name="description" content="Unleash your cricketing potential with CCC Academy. Join us and experience the thrill of the game and improve your skills under our expert coaches." />
                        <meta property="og:url" content="/images/og-images/ccc_academy_og_image.jpg" />
                        <meta name="keywords" content="Cricket"></meta>
                    </Helmet>
                </HelmetProvider>
                
                <HeaderNavPanel />
                <ComingSoonBGBanner />
                <LogoGrid />
                <Footer />
            </>
            

    )
}