import HeaderNavPanel from "./components/HeaderNavPanel"
import UpcomingMatchPanel from "./components/UpcomingMatchPanel"
import DateCalendar from "./components/DateCalendar"
import LogoGrid from "./components/LogoGrid"
import Footer from "./components/Footer"
import { Helmet,HelmetProvider  } from "react-helmet-async"


export default function MatchCalendar()
{
    return(
        <>
            <HelmetProvider>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Club Cricket of Chicago Schedule | Fixtures and Results</title>
                
                    <meta name="description" content="Stay up to date with the latest Club Cricket of Chicago fixtures and results. Check our calendar for upcoming matches." />
                    <meta property="og:url" content="/images/og-images/calendar_og_image.jpg" />
                    <meta name="keywords" content="Cricket"></meta>
                </Helmet>
            </HelmetProvider>
            
           <HeaderNavPanel />
           <UpcomingMatchPanel />
           <DateCalendar />
           <LogoGrid />
           <Footer />
        </>
    )
}