import { useNavigate } from 'react-router-dom';




export default function StaticLogoSlider({leagueData})
{
    const navigate = useNavigate();

    const url_paths = ["/2022/league/bpl-30-30-cup","/2022/league/caso-mid-america-permier-league","/2022/league/caso-nine-sports-fall-t20"]


    let index = leagueData.id;
    let max_index = url_paths.length;


    function HandlePrevClick()
    {
        --index;
        if( index >=1 )
        {
            navigate(url_paths[index-1]);
        }
        else
        {
            index = max_index;
            navigate(url_paths[index-1]);
        }
    }

    function HandleNextClick()
    {
        ++index;
        if( index <=max_index )
        {
            navigate(url_paths[index-1]);
        }
        else
        {
            index = 1;
            navigate(url_paths[index-1]);
        }
    }

    return(
            <div className="leagueLogo_slider flex_grid">
                <div className="LL_Slider_pagIco LL_Slider_prev flex_grid">
                    <img src="/images/slide_pag_ico.png" alt="PAG ICON" onClick={HandlePrevClick} />
                </div>
                <div className="league_logo_ele" style={{ backgroundImage: `url(`+leagueData.leagueBgFlagPath+`)` }} >
                    <div className="leagueStatic_name">
                        <p className="roboto-condensed-bold p4">{leagueData.leagueName}</p>
                    </div>
                    <div className="league_logo">
                        <img src={leagueData.leagueLogoPath} alt="LEAGUE LOGO" />
                    </div>
                    <div className="series_name">
                        <p className="roboto-condensed-bold p4">{leagueData.seriesYear}<br/>{leagueData.seriesName}</p>
                    </div>
                </div>
                <div className="LL_Slider_pagIco LL_Slider_next flex_grid">
                    < img src="/images/slide_pag_ico.png" alt="PAG ICON" onClick={HandleNextClick} />
                </div>
            </div>
    )
}