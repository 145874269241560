import LogoContainer from "./LogoContainer"
import { useRef,useState,useEffect } from "react";
import { Link } from "react-router-dom";
function NavEleIco({setUrl})
{
    return(
          
               <img src={"images/"+setUrl} alt="" className="nav_eleIco"/>     
            
                
      
        
    )
}
function NavEle({goToElement,setLink,setNavText,setNavIco,setNewTab})
{
    let image_ele;
    let target="";
    if(setNavIco!== undefined)
    {
        image_ele = <NavEleIco setUrl={'YoutubeLogo.svg'} />
    }
    else
    {
        image_ele = <></>;
    }
    if(setNewTab=== true)
    {
        target="_blank";
    }
    function handleClick()
    {
        if( setNewTab === false)
        {
            goToElement();
        }
        
    }
    return(
        <div className="nav_ele flex_grid">
            <div className="nav_ele_text">
                <Link to={setLink}  onClick={handleClick} target={target} className="no_underline p2 " >{setNavText}</Link>
            </div>
            
            {image_ele}
                
                  
           
                
            
        </div>

    )
}
function NavEleBtn({setLink,children})
{
    return(
        <div className="nav_ele  ">
            <Link to={setLink} ><button className="nav_ele_btn"><p className=" roboto-condensed-med p2">{children}</p></button></Link>
        </div>
    )
    
}


function NavContainer()
{
    function goToElement()
    {
        
        const ele = document.getElementById("meet_squad");
        console.log("Am I getting Called");
        console.log(ele);
        if(ele!=null)
        {
            ele.scrollIntoView({ behavior: 'smooth' });;
        }
        
        
    }
    return(
        <div className="nav_container flex_grid">
            
            <NavEle setLink={"/calendar"} setNavText={'SCHEDULE'} />
            
            <div className="nav_ele flex_grid dropdown">
                <div className="nav_ele_text">
                    <Link  className="no_underline p2 " >TOURNAMENTS</Link>
                    <div className="dropdown-content">
                        <a href="/2024/series/">2024</a>
                        <a href="/2023/midwest">2023</a>
                        <a href="/2022/league/bpl-30-30-cup">2022</a>
                
                    </div>
                </div>
            </div>
            
            <NavEle setLink={"/players"} goToElement={goToElement} setNavText={'PLAYERS'} />
            <NavEle setNewTab={true} setLink={"https://www.youtube.com/channel/UClH02lUKHkbEsWTlvBtNCkQ"} setNavText={'CCC TV'} setNavIco={'YoutubeLogo.svg'} />
            <NavEle setLink={"/ccc-academy"} setNavText={'CCC ACADEMY'} />
            <NavEle setLink={"/grounds"} setNavText={'GROUNDS'} />
            <NavEle setNewTab={true} setLink={"https://mail.hostinger.com"} setNavText={'Log In'} />
            <NavEleBtn setLink={"/join-us"} >Join now</NavEleBtn>
        </div>
    )
}


function MobileNav()
{   
    function HandleHamClick()
    {
        const ele = document.getElementById("mobNav_hamIcon");
        const mob_ele = document.getElementById("mobileNav_ref");
        if(ele!=null & mob_ele!=null)
        {
            if( ele.classList.contains("nav-open") )
            {
                ele.classList.remove("nav-open");
                mob_ele.classList.remove("active_nav");
            }
            else 
            {
                ele.classList.add("nav-open")
                mob_ele.classList.add("active_nav");
            }
        }
    }

    return(
            <>
                 <div className="mobile_nav_ele" >
                    <div id="mobNav_hamIcon" className="nav_burger mobile-only" onClick={HandleHamClick}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                
            </>
           
    )
}

export default function HeaderNavPanel()
{
    const headerRef = useRef(null);
  
    const [bgColor,setBgColor] = useState("unset");

    useEffect(() => {
        const handleScroll = event => {
         
          const elmnt = headerRef.current;
          
          if(window.scrollY>elmnt.offsetHeight && window.innerWidth>1024)
          { 
            setBgColor("#fff");
            elmnt.classList.add('active_header')
          }
          else
          {
            setBgColor("unset");
            elmnt.classList.remove('active_header');
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);


    return(
        <>
            <header  ref={headerRef} className="main_header base_paddings"  style={{backgroundColor:bgColor}}>
                    <div className=" max_content center_aligned flex_grid">
                        
                        <LogoContainer  setLink={"/"} setClassName={'site_logo'} setUrl={'logo.png'} />

                        <NavContainer />

                        
                        
                        <MobileNav />
                    </div>   
                    
            </header>
        
            <div id="mobileNav_ref" className="mobile_navigation">
                <div className="mob_nav_parent">
                       
                        
                        <div className="mob_nav_ele">
                            <Link to="/calendar" className="roboto-condensed-regular  no_underline p2 content_black" >SCHEDULE</Link>
                        </div>
                        <div className="mob_nav_ele">
                            <Link to="/2024/series" className="roboto-condensed-regular  no_underline p2 content_black" >2024 TOURNAMENTS</Link>
                        </div>
                        <div className="mob_nav_ele">
                            <Link to="/2023/midwest" className="roboto-condensed-regular  no_underline p2 content_black" >2023 TOURNAMENT</Link>
                        </div>
                        <div className="mob_nav_ele">
                            <Link to="/2022/league/bpl-30-30-cup" className="roboto-condensed-regular  no_underline p2 content_black" >2022 TOURNAMENT</Link>
                        </div>
                        <div className="mob_nav_ele">
                            <Link to="/players" className="roboto-condensed-regular  no_underline p2 content_black" >PLAYERS</Link>
                        </div>
                        <div className="mob_nav_ele">
                            <Link to="https://www.youtube.com/channel/UClH02lUKHkbEsWTlvBtNCkQ" className="roboto-condensed-regular  no_underline p2 content_black" >CCC TV</Link>
                        </div>
                        <div className="mob_nav_ele">
                            <Link to="/ccc-academy" className="roboto-condensed-regular  no_underline p2 content_black" >CCC ACADEMY</Link>
                        </div>
                        <div className="mob_nav_ele">
                            <Link to="/grounds" className="roboto-condensed-regular  no_underline p2 content_black" >GROUNDS</Link>
                        </div>
                        <div className="mob_nav_ele">
                            <Link to="https://mail.hostinger.com" className="roboto-condensed-regular  no_underline p2 content_black" >Log In</Link>
                        </div>
                        <div className="mob_nav_ele_btn">
                            <button className="nav_ele_btn roboto-condensed-med p2 "><Link to="/join-us" className="roboto-condensed-regular  no_underline p2 " >Join Now</Link></button>
                        </div>
                </div>    
            </div>   
        </>
       
       
    )
}