import { ParallaxProvider,ParallaxBanner,ParallaxBannerLayer   } from 'react-scroll-parallax';
import LogoContainer from "./LogoContainer"
import CommonButtton from './CommonButtton';

export default function BGParralaxBanner()
{
    return(
        <ParallaxProvider>
            <ParallaxBanner
                    layers={[
                        {
                        image: './PhotoBGJointheTeam.png',
                        speed: -50,
                        },
                    ]}
                  
                    className={"joinUs_bgParrallax"}
                    >
                    <div className="NMC_container flex_grid base_padding center_aligned">
                        <div className="NMC_title">
                            <h4 className="white_color oswald-regular h2">Join the Team</h4>
                        </div>
            
                        <LogoContainer  setClassName={'parrallax_logo site_logo logo_center'} setUrl={'logo.png'} />

                        <CommonButtton setRoutePath={"/join-us"}>Join Now</CommonButtton>

                    </div>       

            </ParallaxBanner>
        </ParallaxProvider>
    )
}