import HeaderNavPanel from "./components/HeaderNavPanel";
import { Helmet,HelmetProvider  } from "react-helmet-async"
import LogoGrid from "./components/LogoGrid" ;
import PlayersPanel from "./components/PlayersPanel";

export default function AllPlayers()
{
    return(
        <>
        
                <HelmetProvider>
                        <Helmet>
                            <meta charSet="utf-8" />
                            <title>Tournaments and Leagues | CCC Stats and Standings</title>
                        
                            <meta name="description" content="Get access to the latest cricket stats and standings for CCC's tournaments and leagues. Stay updated with scores and team performances. " />
                            <meta property="og:url" content="/images/og-images/midwest_og_image.jpg" />
                            <meta name="keywords" content="Cricket"></meta>
                        </Helmet>
                </HelmetProvider>
            
                <HeaderNavPanel />
                <PlayersPanel />    
                <LogoGrid />
        </>
    )
}