import { useState, useEffect } from 'react';

function StandingListEle({team})
{
   
    return(
        <div className="SListing_team_ele flex_grid">
            <div className="SListing_name flex_grid">
                <div className="team_ico">
                    <img src={team.teamIcon} alt="Team Icon" />
                </div>
                <div className="team_name">
                    <p className="roboto-condensed-regular  light_grey p5" >{team.teamName}</p>
                </div> 
            </div>
            <div className="SListing_win">
                <p className="roboto-condensed-bold light_grey p5" >{team.win}</p>
            </div>
            <div className="SListing_lose">
            <p className="roboto-condensed-bold light_grey p5" >{team.lose}</p>
            </div>    
        </div>


    )
}



export default function LeagueStaticStandings({standingList})
{
    

    return(
            <div className="LT_gridEle LT_league_standings">
                

                <div className="standings_listing">
                    
                    <div className="standings_title">
                        <p className="p4 grey_text roboto-condensed-bold">Standings </p>
                    </div>
                    
                    <div className="SListing_header flex_grid">
                        <div className="SListing_name">
                            <p className="roboto-condensed-bold light_grey p5" >Teams</p>
                        </div>
                        <div className="SListing_win">
                            <p className="roboto-condensed-bold light_grey p5" >W</p>
                        </div>
                        <div className="SListing_lose">
                        <p className="roboto-condensed-bold light_grey p5" >L</p>
                        </div>
                    </div>
                    
                    <div className="SListing_listing ">
                  
                        {
                            standingList.map((team)=>
                            {
                                  return(
                                    <StandingListEle key={team.id} team={team} />
                                  )  
                            })
                        }
                     
                        
                    </div>
                </div>
            </div>
    )
}