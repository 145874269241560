import Flickity from 'react-flickity-component'
import {  useEffect } from 'react';
import MatchStaticResultEle from './MatchStaticResultEle';

export default function LeagueStaticResultsSlider({resultsList})
{
   
    
    let local_rowList = [];
    const results_length = resultsList.length;
    let local_index=0;
    useEffect(()=>{genResultsList()})

    function genResultsList()
    {
     
      
      resultsList.forEach((result,index)=>{
                          
                          
            if( index<=(results_length/2) )
            {
              
              if(local_index>=results_length)
              {
                local_index=0;
                local_rowList=[];
              }
              local_rowList.push(<div key={"LSRS_ele_"+resultsList[local_index].id} className="LSRS_ele">
                                <MatchStaticResultEle key={resultsList[local_index].id} result={resultsList[local_index]} />
                                <MatchStaticResultEle key={resultsList[local_index+1].id} result={resultsList[local_index+1]} />
                              </div>)
              local_index=local_index+2;
            }
            else if(index>(results_length/2))
            {
              
            }
            
        })
        
      
        return () => {
          local_rowList=[];
          local_index=0;
      }
    }
   
    const flickityOptions = {
        initialIndex: 0,
        wrapAround:false,
        pageDots:true,
        cellAlign:'left',
        prevNextButtons:false
    }

    return(
            <section className="LSRS_container">
                <div className="LSRS_title">
                    <h4 className="roboto-condensed-bold h4 white_color">Results</h4>
                </div>
                <Flickity
                   
                    className={'carousel flikity-carasouel'} // default ''
                    elementType={'div'} // default 'div'
                    options={flickityOptions} // takes flickity options {}
                    disableImagesLoaded={false} // default false
                
                    reloadOnUpdate={true} // default false
                     // default false
                >
                    {local_rowList}
                     
                     
                </Flickity>
            </section>
    )
}