import { useNavigate } from "react-router-dom";

export default function CommonButtton({children,setRoutePath})
{
    const navigate = useNavigate();

    

    function handleClick()
    {
        
        navigate(setRoutePath);
    }
    return(
        <div className="common_buttonHolder">
            <button className="roboto-condensed-bold p2 " onClick={handleClick} >{children}</button>
        </div>
    )
}