export default function NewLeagueLogoSlider({flagImage})
{
    return(
            <div className="league_logo_slider">
                   <div className="leagueLogo_slider flex_grid">
                    
                    <div className="newleague_logo_ele" style={{ backgroundImage: `url("${flagImage}")` }} >
                        {/* <div className="leagueStatic_name">
                            <p className="roboto-condensed-bold p4">Midwest Cricket Conference</p>
                        </div>
                        <div className="league_logo">
                            <img src="/images/league-logos/mwcc_logo.png" alt="LEAGUE LOGO" />
                        </div>
                        <div className="series_name">
                            <p className="roboto-condensed-bold p4">2023<br/>RedBall T30</p>
                        </div> */}
                    </div>
                    
                </div>
                    
            </div>   
    )
}