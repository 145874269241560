import { useState } from "react";
import { useEffect } from "react";
export default function TimeCounter({matchDate,matchTime})
{   
    
    
    const [days,setDays] = useState('');
    const [hours,setHours] = useState('');
    const [minutes,setMinutes] = useState('');
    const [seconds,setSeconds] = useState('');
   
    const convertTime = timeStr => {
        const [time, modifier] = timeStr.split(' ');
        
        let [hours, minutes] = time.split(':');
        if (hours === '12') {
           hours = '00';
        }
        if (modifier === 'PM') {
           hours = parseInt(hours, 10) + 12;
        }
        return `${hours}:${minutes}`;
     };


    function convertTimestamp(matchDate,matchTime) {
        const months = ["January","Feburary","March","April","May","June","July","August","September","October","November","December"];
        console.log("RECIEVED TIME:"+matchTime);
        console.log("RECIEVED DATE:"+matchDate);
        let formatted_date;
        let date_splitData = matchDate.split("-");
        
        let year = date_splitData[0],month = date_splitData[1],day = date_splitData[2];
        formatted_date = months[parseInt(month)-1]+" "+parseInt(day)+", "+year+" "+convertTime(matchTime) ;

        return formatted_date;
        // const date = new Date(timestamp);
        // const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
        // console.log("TIME:"+convertTime(matchTime));
        // const formattedDate = date.toLocaleString('en-US', options).replace(/(\d{2}:\d{2}:\d{2})/, convertTime(matchTime));
        //console.log("FORMAT:"+formattedDate);
        //const [, month, day, year] = formattedDate.match(/(\w{3}) (\d{1,2}), (\d{4})/);
        //return formattedDate;
      }


    useEffect(()=>{initCounter()},[matchDate,matchTime]);

    function initCounter()
    {
        let formattedDate="May 14, 2023 13:30:00";
        if(matchDate!==undefined && matchDate!=="" && matchTime!==undefined && matchTime!=="") 
        {
            
            
            formattedDate = convertTimestamp(matchDate,matchTime);
            console.log(formattedDate);
        }
        // Set the date we're counting down to
        var countDownDate = new Date(formattedDate).getTime();

        // Update the count down every 1 second
        var x = setInterval(function() {

        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        setDays( Math.floor(distance / (1000 * 60 * 60 * 24)) );
        setHours(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) );
        setMinutes( Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)) );
        setSeconds( Math.floor((distance % (1000 * 60)) / 1000) );
           
       

        // If the count down is finished, write some text
        if (distance < 0) {
            clearInterval(x);
            
        }
        }, 1000);


        return () => {
            clearInterval(x);
            formattedDate="";
        }
    }












    
    return(
        <div className="counter flex_grid">
                                    <div className="counter_ele">
                                        <p className="oswald-med p4">DAY</p>
                                        <div id="day" className="counter_num oswald-semi-bold">{days}</div>
                                        
                                    </div>
                                    <div className="counter_ele">
                                        <p className="oswald-med p4">HOUR</p>    
                                        <div id="hour"  className="counter_num oswald-semi-bold">{hours}</div>
                                        
                                    </div>
                                    <div className="counter_ele">
                                        <p className="oswald-med p4">MINUTE</p>
                                        <div id="minute"  className="counter_num oswald-semi-bold">{minutes}</div>
                                        
                                    </div>
                                    <div className="counter_ele">
                                        <p className="oswald-med p4">SECOND</p>
                                        <div id="second"  className="counter_num oswald-semi-bold">{seconds}</div>
                                    </div>
                                </div>
    )
}