

export default function LeaguePositionStats({playerData})
{
    return(
        <>
            <section className="posStats_container">
                <div className="PSC_header flex_grid">
                    <div className="PSC_title">
                        <h2 className="roboto-condensed-bold h4 white_color">Position</h2>
                    </div>
                    <div className="PSC_rank">
                        <p className="roboto-condensed-bold  brand_orange h4">{playerData.rank}</p>
                    </div>
                </div>
                <div className="PSC_body">
                    <div className="PSC_stats_grid">
                        <div className="PSC_stats_ele">
                            <p className="roboto-condensed-bold h4 brand_orange" >{playerData.matches}</p>
                            <p className="roboto-condensed-bold p2 white_color">Matches</p>
                        </div>
                        <div className="PSC_stats_ele">
                            <p className="roboto-condensed-bold h4 brand_orange" >{playerData.won}</p>
                            <p className="roboto-condensed-bold p2 white_color">Won</p>
                        </div>
                        <div className="PSC_stats_ele">
                            <p className="roboto-condensed-bold h4 brand_orange" >{playerData.lost}</p>
                            <p className="roboto-condensed-bold p2 white_color">Lost</p>
                        </div>
                        <div className="PSC_stats_ele">
                            <p className="roboto-condensed-bold h4 brand_orange" >{playerData.tie}</p>
                            <p className="roboto-condensed-bold p2 white_color">Tie</p>
                        </div>
                        <div className="PSC_stats_ele">
                            <p className="roboto-condensed-bold h4 brand_orange" >{playerData.nr}</p>
                            <p className="roboto-condensed-bold p2 white_color">N/R</p>
                        </div>
                        <div className="PSC_stats_ele">
                            <p className="roboto-condensed-bold h4 brand_orange" >{playerData.winper}</p>
                            <p className="roboto-condensed-bold p2 white_color">Win%</p>
                        </div>
                    </div>
                </div>
            </section>    
            
        </>
    )
}