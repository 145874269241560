import { useState, useEffect } from 'react';

function PlayerCardEle({ player }) {
    return (
        <div className="playerCardEle">
            <div className="playerPlayingStyle">
                <img src={player.playingRole === "Batsman" ? "/images/batsman.svg" : "/images/allrounder.svg"} alt="" />
            </div>

            <div className="playerImage">
                <div className="playerImageBg"  style={{ backgroundImage: `url(https://cricclubs.com${player.profilepic_file_path})` }}  ></div>
                
            </div>
            <div className="playerinfo">
                <h2>{`${player.firstName} ${player.lastName}`}</h2>
                <p className="playerStyle">{player.playingRole}</p>
            </div>
        </div>
    );
}

export default function PlayersPanel() {
    const [teamAPlayers, setTeamAPlayers] = useState(null);
    const [teamBPlayers, setTeamBPlayers] = useState(null);
    const [teamCPlayers, setTeamCPlayers] = useState(null);

    useEffect(() => {
        async function fetchTeamPlayers(teamId, setPlayers) {
            try {
                const response = await fetch(`https://ccapi.cricclubs.com/CCAPI/team/getTeamPlayers?clubId=63&teamId=${teamId}`);
                const data = await response.json();
                setPlayers(data.data.teamPlayers);
            } catch (error) {
                console.error('Error fetching team players:', error);
            }
        }

        fetchTeamPlayers(2240, setTeamAPlayers);
        fetchTeamPlayers(2202, setTeamBPlayers);
        fetchTeamPlayers(2186, setTeamCPlayers);
    }, []);

    if (!teamAPlayers || !teamBPlayers || !teamCPlayers) {
        return  (<section className="LSC_container base_paddings">
                    <div className="loadingAnimation">
                        <img src="/images/loading.gif" alt=" ladoing GIF" />
                    </div>
                </section>);
    }

    return (
        <section className="allPlayersPanel_header base_paddings">
            <div className="LSC_parent max_content center_aligned">
                <h2 className="roboto-condensed-bold h4 allpp_title">TEAM A</h2>
                <div className="players_panel_container">
                    {teamAPlayers.map(player => <PlayerCardEle key={player.playerID} player={player} />)}
                </div>

                <h2 className="roboto-condensed-bold h4 allpp_title">TEAM B</h2>
                <div className="players_panel_container">
                    {teamBPlayers.map(player => <PlayerCardEle key={player.playerID} player={player} />)}
                </div>

                <h2 className="roboto-condensed-bold h4 allpp_title">TEAM C</h2>
                <div className="players_panel_container">
                    {teamCPlayers.map(player => <PlayerCardEle key={player.playerID} player={player} />)}
                </div>
            </div>
        </section>
    );
}
