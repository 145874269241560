import SocialMediaPanel from "./SocialMediaPanel"

export default function Footer()
{
    return(
        <>
            <section className="footer_parent base_paddings">
                <footer className="site_footer center_aligned max_content">
                        <div className="footer_title">
                            <h5 className="oswald-bold p2">Club Cricket Of Chicago</h5>
                        </div>
                        <div className="footer_socialPanel">
                            <SocialMediaPanel />
                        </div>
                        
                        
                        <div className="cotact_info">
                            <div className="contact_info_title">
                                <h6 className="oswald-bold p2">CONTACT US</h6>
                            </div>
                            <div className="info_address">
                                <p className="roboto-condensed-light p4">211 Valarie St, Glenview 60025,<br/> Illinois, United State<br/> +1-517-358- 2588</p>
                                <a className="roboto-condensed-light p4 no_href" href="mailto:connect@clubcricketofchicago.com">connect@clubcricketofchicago.com</a>
                            </div>
                        </div>
                </footer>   
            </section>
            
        </>
    )
}