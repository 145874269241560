import GroundsData from "../data/grounds.json"
import { useState,useEffect } from "react";
function OPInfoEle({setText,setNum})
{
    return(
            <div className="OPIE_ele flex_grid">
                <div className="OPIE_ele_text">
                    <h5 className="roboto-condensed-med p2 white_color">{setText}</h5>
                </div>
                <div className="OPIE_ele_num">
                    <p className="roboto-condensed-bold h4 brand_orange">{setNum}</p>
                </div>
            </div>
    )
}


function OverlayPanel({GroundsData})
{

    const [index,setIndex] = useState(GroundsData.length);

 
    function HandlePrevClick()
    {
        
        
        if( index >1)
        {
            let temp_index = index;
            console.log("TEMP INDEX:"+temp_index);
            let dyn_clsName = temp_index+"_ground";
            let fade_element = document.getElementById(dyn_clsName);
            fade_element.classList.add("transparent_bg");
            temp_index = temp_index-1;
            setIndex(temp_index);
            if( temp_index == 10 )
            {
                document.getElementById("homeGroundEle").classList.remove("hidden_ele");
            }
            else 
            {
                document.getElementById("homeGroundEle").classList.add("hidden_ele");
            }
        }
        else
        {
            setIndex(GroundsData.length);
            let temp_index = GroundsData.length;
            let fade_elements = document.querySelectorAll(".crossFade_imgEle");
            
            fade_elements.forEach((element)=>{
                
                element.classList.remove("transparent_bg");
            })
            if( temp_index == 10 )
            {
                document.getElementById("homeGroundEle").classList.remove("hidden_ele");
            }
            else 
            {
                document.getElementById("homeGroundEle").classList.add("hidden_ele");
            }
        }
        
        
    }

    function HandleNextClick()
    {
        
        if( index == GroundsData.length)
        {
            setIndex(1);
            let temp_index = 1;
            let fade_elements = document.querySelectorAll(".crossFade_imgEle");
            fade_elements.forEach((element)=>{
               
                element.classList.add("transparent_bg");
            })
            let fade_element = document.getElementById("1_ground");
            fade_element.classList.remove("transparent_bg");
            if( temp_index == 10 )
            {
                document.getElementById("homeGroundEle").classList.remove("hidden_ele");
            }
            else 
            {
                document.getElementById("homeGroundEle").classList.add("hidden_ele");
            }
        }
        else
        {
            let temp_index = index+1;
            setIndex(temp_index);
            let dyn_clsName = temp_index+"_ground";
            
            let fade_element = document.getElementById(dyn_clsName);
            fade_element.classList.remove("transparent_bg");
            if( temp_index == 10 )
            {
                document.getElementById("homeGroundEle").classList.remove("hidden_ele");
            }
            else 
            {
                document.getElementById("homeGroundEle").classList.add("hidden_ele");
            }
        }
        
       
        console.log("INDEX:"+index);
        
    }
    return(
                
                <div className="OP_container ">
                    <div className="OP_parent  ">
                        <div className="OP_title">
                            <h2 className="oswald-bold h1 white_color" >{GroundsData[index-1].groundName}</h2>
                        </div>
                        <div className="OP_pag_bar flex_grid">
                            <div className="OP_pag_bar_ele prev_btn" onClick={HandlePrevClick} >
                                <div className="pag_bar_ele_image">
                                    <img src="/images/slide_pag_ico.png" alt=""/>
                                </div>
                                <p className="roboto-condensed-regular p6 white_color" >Previous</p>
                            </div>
                            <div className="OP_pag_bar_ele next_btn" onClick={HandleNextClick} > 
                                <div className="pag_bar_ele_image">
                                    <img src="/images/slide_pag_ico.png" alt=""/>
                                </div>
                                <p className="roboto-condensed-regular p6 white_color" >Next</p>
                            </div>
                        </div>
                        <div className="mob_only mobGround_viewer">
                           <img src={GroundsData[index-1].groundImage} alt="GROUND IMAGEW" />
                            
                        </div>
                        <div className="OP_loc_container flex_grid">
                            <div className="OP_loc_ico">
                                <img src="/images/orangeBg_locIco.svg" alt="" />
                                
                            </div>
                            <p className="roboto-condensed-regular white_color p4" >{GroundsData[index-1].address}</p>
                        </div>
                        <div className="OP_info_container">
                            <OPInfoEle setText="Matches" setNum={GroundsData[index-1].matches} />
                            <OPInfoEle setText="First Batting Team Wins," setNum={GroundsData[index-1].avgFirstInning} />
                            <OPInfoEle setText="Second  Batting Team Wins," setNum={GroundsData[index-1].avgSecondInning} />
                            <OPInfoEle setText="Avg. Score, 1st Inning" setNum={GroundsData[index-1].firstBattingTeam} />
                            <OPInfoEle setText="Avg. Score, 2nd Inning" setNum={GroundsData[index-1].secondBattingTeam} />

                        </div>
                        <div className="OP_dis_text">
                            <p className="white_color roboto-condensed-med p5">*Midwest Cricket League | Season 2022.|</p>
                        </div>
                    </div>
                
                </div>
           
            
    )
}
function BGSlider({GroundsData})
{

    return(<>
            <div className="bg_slider">
                {
                    GroundsData.map((ground,index)=>{


                        return(
                            <picture key={ground.id+"__PRCINDEX"+index} >
                                <source key={ground.id+"__SRCINDEX"+index} srcSet={ground.groundMobileImage} media="(max-width: 1024px)" />
                               
                                <img id={""+ground.id+"_ground"} key={ground.id} className={"crossFade_imgEle "} src={ground.groundImage} alt="" />
                            </picture>
                                
                        )
                    })
                }
                
            </div>
            <div id="homeGroundEle" className="homeGround_logo ">
                <img src="/images/logo.png" alt="Home Ground Logo" />
                <p className="oswald-bold p1" >Home Ground</p>
            </div>
            </>
    )
}
export default function GroundSlider()
{
    return(
            <section className="GS_container " >
                 
                 <BGSlider GroundsData={GroundsData} />
                 <OverlayPanel GroundsData={GroundsData} />
            </section>

    )
}