export default function ComingSoonBGBanner()
{
    return(
            <section className="CSBB_container base_paddings" style={{backgroundImage:`url("/images/ccc_comingSoon_bg.png")`}} >
                <div className="CSBB_parent max_content center_aligned" >
                    <div className="CSBB_overlayText">
                        <div className="CSBB_title">
                            <h2 className="oswald-bold h2 ">CCC ACADEMY</h2>
                        </div>
                        <div className="CSBB_bodyText">
                            <p className="roboto-condensed-med p4">Unleash your cricketing potential with CCC Academy. Join us and<br class="desk_only" /> experience the thrill of the game while improving your skills under the<br class="desk_only" /> guidance of our expert coaches.</p>
                        </div>
                        <div className="CSBB_comingsoon_copy">
                            <p className="oswald-med h3">COMING SOON</p>
                        </div>
                    </div>
                </div>
            </section>
    )
}

