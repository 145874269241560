import LeagueStandings from "./LeagueStandings";
import FixturesAndResults from "./FixturesAndResults";
import PlayerOfTheWeek from "./PlayerOfTheWeek";
import LeagueLogoSlider from "./LeagueLogoSlider";
import LeagueHighlights from "./LeagueHighlights";
import NumberZone from "./NumberZone";
import { useEffect,useState } from "react";

export default function LeagueStatsContainer()
{
    useEffect(()=>{fetchBattingStats()},[]);
    useEffect(()=>{fetchBowlingStats()},[]);
    useEffect(()=>{fetchRankingStats()},[]);
    useEffect(()=>{fetchLeagueStats()},[]);

    const [battingInfo,setBattingInfo]=useState([]);
    const [bowlingInfo,setBowlingInfo]=useState([]);
    const [RankingInfo,setRankingInfo]=useState([]);
    const [LeagueInfo,setLeagueInfo]=useState([]);

    async function fetchBattingStatsJSON() {
        let url = `https://ccapi.cricclubs.com/CCAPI/stats/getBattingStats?X-Auth-Token=&clubId=63&seriesId=279&teamId=2004&limit=1000`;
        console.log(url);
        const response = await fetch('https://ccapi.cricclubs.com/CCAPI/stats/getBattingStats?X-Auth-Token=&clubId=63&seriesId=279&teamId=2004&limit=1000');
        const battingStats = await response.json();
        return battingStats;

    }

    async function fetchBowlingStatsJSON() {
        const response = await fetch('https://ccapi.cricclubs.com/CCAPI/stats/getBowlingStats?X-Auth-Token=&clubId=63&seriesId=279&teamId=2004&limit=1000');
        const bowlingStats = await response.json();
        return bowlingStats;

    }

    async function fetchRankingStatsJSON() {
        const response = await fetch('https://ccapi.cricclubs.com/CCAPI/league/getPlayerRankings?X-Auth-Token=&clubId=63&seriesId=279&teamId=2004&limit=1000');
        const rankingStats = await response.json();
        return rankingStats;

    }

    async function fetchLeagueStatsJSON() {
        const response = await fetch('https://ccapi.cricclubs.com/CCAPI/match/getMatches?XAuthToken=&clubId=63&seriesId=279&teamId=2004&limit=1000');
        const leagueStats = await response.json();
        return leagueStats;

    }

    function fetchBattingStats()
    {
        fetchBattingStatsJSON().then(battingStats => {
            
            setBattingInfo(battingStats.data)
           

        });
    }

    function fetchBowlingStats()
    {
        fetchBowlingStatsJSON().then(bowlingStats => {        
           
            setBowlingInfo( bowlingStats.data );
           
        });
    }

    function fetchRankingStats()
    {
        fetchRankingStatsJSON().then(rankingStats => {
           
            setRankingInfo( rankingStats.data );

        });
    }



    function fetchLeagueStats()
    {
        fetchLeagueStatsJSON().then(leagueStats => {
           
            setLeagueInfo( leagueStats.data );

        });
    }

    return(
            <>
            <section className="LSC_container base_paddings">
                <div className="LSC_parent max_content center_aligned flex_grid">
                        <div className="LSC_smallCol_grid">
                             <LeagueLogoSlider />  

                             <LeagueStandings /> 
                        </div>
                        <div className="LSC_BigCol_grid">
                            <PlayerOfTheWeek batterInfo={battingInfo[0]} bowlerInfo={bowlingInfo[0]} />
                            <LeagueHighlights battingInfo={battingInfo} bowlingInfo={bowlingInfo} RankingInfo={RankingInfo} LeagueInfo={LeagueInfo} />
                            
                        </div>
                        <div className="LSC_smallCol_grid">
                            <FixturesAndResults fixtureCount={7} resultsCount={7} seriesTeamMappingArr={{seriesId:279,teamId:2004}} />
                        </div>
                </div>
               
                <NumberZone />
            </section>
            
            </>
            
            
    )
}