import SocialMediaPanel from "./SocialMediaPanel";

import { Link } from "react-router-dom";
function HeroContentBlock()
{
    return(
            <div className="hero_content_block_parent   ">
                  
                    <div className="h2_bg">
                        <img src="/images/h2_bg.png" alt="Hero Image Two" />
                    </div>
                    <div className="h1_bg">
                        <img src="/images/h1_bg.png" alt="Hero Image One" />
                    </div>
                    <div className="h3_bg">
                        <img src="/images/h3_bg.png" alt="Hero Image Three" />
                    </div>
                    {/* <div className="hero_content_block   ">
                        <div className="HeroContent_title">
                            <h1 className="white_color oswald-bold h1" >CLUB CRICKET OF<br className="desk_only" />  CHICAGO</h1>
                        </div>
                        <div className="HeroContent_copy">
                            <p className=" roboto-condensed-regular brand_orange p2" >Come build your body, mind<br className="mob_only"/> and ambition<br className="desk_only" /> with CCC</p>
                        </div>
                        <div className="HeroContent_btn">
                            <Link to="/join-us"><button className="roboto-condensed-bold p2 " >Join Now</button></Link>
                        </div>
                    </div>
                    <div className="rightBanner">
                        <div className="socialMediaPanel">
                            <SocialMediaPanel />
                        </div>
                        <div className="heroLogo_clckable">
                           
                            <div className="logo_container cricclubs_logo"   >
                
                                <Link to="https://cricclubs.com/" target="_blank"> <img src={"/images/cric_club_logo_typeface.svg"}  alt="Mill House Logo" /></Link>  
                                
                            </div>
                        </div>
                    </div> */}
            </div>
            
    );
}



export default function HeroBanner()
{
    return(
            <div className="hero-banner " >
                    <HeroContentBlock />    

            </div>
    )
}