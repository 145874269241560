import { Link } from "react-router-dom"

function LogoEle({setPath,setUrl})
{
    return(
        <div className="LG_ele flex_grid">
            <Link to={setPath} target="_blank" ><img src={"/images/sponsors/"+setUrl} alt="" /></Link>
        </div>
    )
}

export default function LogoGrid()
{
    return(
        <section className="LG_container ">
                {/* <div className="base_paddings">
                    <div className="LG_parent2 max_content center_aligned">
                        <LogoEle setPath="https://milhouseinc.com/" setUrl="MillhouseLogo.svg" />     
                    </div>
                </div> */}
                <div className="LG_parentParent base_paddings">
                    <div className="LG_parent max_content center_aligned">
                        
                        <LogoEle setPath="https://www.berrylynnco.com/" setUrl="berrylynnco logo.png" />
                        <LogoEle setPath="https://www.114studios.com/" setUrl="114studios logo.png" />
                        <LogoEle setPath="https://prosportstudio.com/" setUrl="Pro Sports logo.png" />
                    </div>
                </div>
                
        </section>
    )
}