

export default function MatchLocation({className,logoWhite,children})
{
    let img_url = "/images/location.svg"
    if( logoWhite === true)
    {
        img_url = "/images/location_white.svg"
    }


    return(
        <div className={`match_location ${className}`} >
           
            <div className="match_loc_ico">
                <img src={img_url} alt="Locaion Icon" />
            </div>
            <div className="match_loc_info">
                <p className="roboto-condensed-regular p6" >{children.substring(0,24)}</p>
            </div>
            
        </div>
    )
}