import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import RenderBowlingDataTable from './RenderBowlingDataTable';
import RenderBattingDataTable from './RenderBattingDataTable';
import RenderFieldingDataTable from './RenderFieldingDataTable';
import RenderRankingDataTable from './RenderRankingDataTable';
// function DataTableHeader()
// {
//     return(
//             <thead>
//                 <tr>
//                     <th style={{width:'4%',textAlign:'center'}} className="roboto-condensed-regular white_color p5" >#</th>
//                     <th style={{width:'23%',textAlign:'left'}} className="roboto-condensed-regular white_color p5" >Player</th>
//                     <th className="roboto-condensed-regular white_color p5 border_th" >Mat</th>
//                     <th className="roboto-condensed-regular white_color p5 border_th" >Ins</th> 
//                     <th className="roboto-condensed-regular white_color p5 border_th" >Balls</th> 
//                     <th className="roboto-condensed-regular white_color p5 border_th" >Runs</th> 
//                     <th className="roboto-condensed-regular white_color p5 border_th" >Wkts</th> 
//                     <th className="roboto-condensed-regular white_color p5 border_th" >Pts</th>
//                     <th className="roboto-condensed-regular white_color p5 border_th" >Cths</th>
//                     <th className="roboto-condensed-regular white_color p5 border_th" >4W</th> 
//                     <th className="roboto-condensed-regular white_color p5 border_th" >5W</th> 
                    
//                     <th className="roboto-condensed-regular white_color p5 border_th" >DB</th> 
                    
//                 </tr>    
//             </thead>
//     )
// }



// function DataTableRow({tableRow,indexVal})
// {
//     console.log(tableRow);
//     return(
//             <tr>
//                 <td style={{width:'4%',textAlign:'center'}} className="roboto-condensed-regular white_color p5" >{++indexVal}</td>
//                 <td style={{width:'23%',textAlign:'left'}} className="roboto-condensed-regular white_color p5" >{tableRow.firstName} {tableRow.lastName}</td>
//                 <td className="roboto-condensed-regular white_color p5 " >{tableRow.matches}</td>
//                 <td className="roboto-condensed-regular white_color p5 " >{tableRow.innings}</td> 
//                 <td className="roboto-condensed-regular white_color p5 " >{tableRow.balls}</td> 
//                 <td className="roboto-condensed-regular white_color p5 " >{tableRow.runs}</td> 
//                 <td className="roboto-condensed-regular white_color p5 " >{tableRow.wickets}</td> 
//                 <td className="roboto-condensed-regular white_color p5 " >{tableRow.points}</td> 
//                 <td className="roboto-condensed-regular white_color p5 " >{tableRow.catches}</td> 
//                 <td className="roboto-condensed-regular white_color p5 " >{tableRow.fourWickets}</td> 
//                 <td className="roboto-condensed-regular white_color p5 " >{tableRow.fiveWickets}</td> 
//                 <td className="roboto-condensed-regular white_color p5 " >{tableRow.dotBalls}</td> 
               
                
//             </tr>
//     )
// }


// function RenderDataTable({tableData})
// {
//     if(tableData == undefined)
//     {
//         tableData = [];
        
//     }
//     console.log(typeof(tableData));
//     return(
//             <table cellSpacing="0">
//                 <DataTableHeader />
                
//                 <tbody>
//                     {
//                         tableData.map((tableRow,index)=>{
//                             return(
//                                     <DataTableRow tableRow={tableRow} indexVal={index} />
//                             )
//                         })
//                     }
//                 </tbody>

//             </table>
//     )
// }






function NuberTable()
{    
    
    return(
            <div className="NZ_NT_container">
                    <Tabs>
                            <div className="NZ_NT_tabList_parent">
                                <TabList>
                                    <Tab className="roboto-condensed-bold react-tabs__tab p4 grey_text" >Batting</Tab>
                            
                                    <Tab className="roboto-condensed-bold react-tabs__tab p4 grey_text" >Bowling</Tab>
                                    <Tab className="roboto-condensed-bold react-tabs__tab p4 grey_text" >Fielding</Tab>
                            
                                    <Tab className="roboto-condensed-bold react-tabs__tab p4 grey_text" >Rankings</Tab>
                                </TabList>
                            </div>
                            <TabPanel className="react-tabs__tab-panel NZNT_table batting_table" >
                                <RenderBattingDataTable />
                            </TabPanel>   

                            <TabPanel className="react-tabs__tab-panel NZNT_table bowling_table" >
                                <RenderBowlingDataTable />    
                            </TabPanel>  

                            <TabPanel className="react-tabs__tab-panel NZNT_table fielding_table" >
                                <RenderFieldingDataTable />
                            </TabPanel>  

                            <TabPanel className="react-tabs__tab-panel NZNT_table rankings_table" >
                                <RenderRankingDataTable />
                            </TabPanel> 
                    </Tabs>  
            </div>
    )
}
export default function NumberZone()
{
    return(
            <section className="NZ_container max_content center_aligned ">
                <div className="NZ_title">
                    <h5 className="oswald-bold p1 white_color" >NUMBER ZONE</h5>
                </div>
                <NuberTable />
            </section>
    )
}