import { ParallaxProvider, ParallaxBanner } from 'react-scroll-parallax';
import CommonButton from './CommonButtton';
import TimeCounter from './TimeCounter';
import { useState, useEffect } from 'react';

export default function NewSeasonCounter() {
    let seriesTeamArray = [
        {
            "seriesId": 298,
            "teamId": 2186
        },
        {
            "seriesId": 300,
            "teamId": 2202
        },
        {
            "seriesId": 303,
            "teamId": 2240
        }
    ];

    const [games, setGames] = useState([]);
    let matchDate, matchTime;

    useEffect(() => {
        fetchUpcomingGame();
    }, []);

    async function fetchGamesJSON(seriesId, teamId) {
        let url = `https://ccapi.cricclubs.com/CCAPI/match/getSchedule?clubId=63&seriesId=${seriesId}&teamId=${teamId}&limit=100&X-Auth-Token=`;
        console.log("url: "+url)
        const response = await fetch(`https://ccapi.cricclubs.com/CCAPI/match/getSchedule?clubId=63&seriesId=${seriesId}&teamId=${teamId}&limit=100&X-Auth-Token=`);
        

        const my_games = await response.json();
        return my_games;
    }

    function fetchUpcomingGame() {
        let allGames = [];

        Promise.all(
            seriesTeamArray.map(async (item) => {
                const my_games = await fetchGamesJSON(item.seriesId, item.teamId);
                if (my_games.data && my_games.data.fixtureList) {
                    allGames.push(...my_games.data.fixtureList);
                }
            })
            
        ).then(() => {
            // Filter to include only future games
            const currentDate = new Date();
            console.log(allGames);
            console.log(new Date(allGames[2].fixedFormatDate));
            console.log(currentDate);
            console.log(new Date(allGames[0].fixedFormatDate) > currentDate);
            const futureGames = allGames.filter(game => new Date(game.fixedFormatDate) > currentDate);

            // Sort the games to find the most recent one
            futureGames.sort((a, b) => new Date(a.fixedFormatDate) - new Date(b.fixedFormatDate));
            console.log(futureGames);
            setGames(futureGames);
        });
    }

    return (
        <ParallaxProvider>
            <ParallaxBanner
                layers={[
                    {
                        image: './images/NS_counterBg.jpg',
                        speed: -50,
                    },
                ]}
                className={"my_parrallax_banner"}
            >
                <div className="NMC_container flex_grid base_padding center_aligned">
                    <div className="NMC_title">
                        <h4 className="white_color oswald-bold h2">Next Season Starts in</h4>
                    </div>
                    {/* {games.map((game, index) => {
                        if (index < 1) {
                            matchDate = game.fixedFormatDate;
                            matchTime = game.time;
                            console.log("MATCH DATE:", matchDate);
                            console.log("MATCH TIME:", matchTime);
                            return (
                                // <TimeCounter key={index} matchDate={matchDate} matchTime={matchTime} />
                               
                            )
                        } else {
                            return null;
                        }
                    })} */}
                     <TimeCounter matchDate="2025-03-02" matchTime="12:00 PM" />
                    <CommonButton setRoutePath={"/calendar"}>Schedule</CommonButton>
                </div>
            </ParallaxBanner>
        </ParallaxProvider>
    )
}
