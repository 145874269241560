import Footer from "./components/Footer";
import HeaderNavPanel from "./components/HeaderNavPanel";
import LeagueStaticStatsContainer from "./components/LeagueStaticStatsContainer";
import LogoGrid from "./components/LogoGrid";
import staticLeagueData from './data/caso-2.json';
import { Helmet,HelmetProvider  } from "react-helmet-async"

export default function CasoNineSportsLeague()
{
    return(
        <>
            <HelmetProvider>
                <Helmet>
                        <meta charSet="utf-8" />
                        <title>CASO Nine Sports Fall T20 2022 | CCC Stats and Standings</title>
                    
                        <meta name="description" content="Get access to the CASO Nine Sports Fall T20 2022 Stats and Standings for Club Cricket of Chicago." />
                        <meta property="og:url" content="/images/og-images/caso_nin_og_image.jpg" />
                        <meta name="keywords" content="Cricket"></meta>
                </Helmet> 
            </HelmetProvider>
            
            <HeaderNavPanel /> 
            <LeagueStaticStatsContainer leagueData={staticLeagueData} />  
            <LogoGrid />
            <Footer />
        </>
    )
}