import { useEffect,useState } from 'react';

function DataRankingTableHeader()
{
    return(
            <thead>
                 <tr>
                    <th style={{width:'4%',textAlign:'center'}} className="roboto-condensed-regular white_color p5" >#</th>
                    <th style={{width:'23%',textAlign:'left'}} className="roboto-condensed-regular white_color p5" >Player</th>
                    <th className="roboto-condensed-regular white_color p5 border_th" >Batting Points</th>
                    <th className="roboto-condensed-regular white_color p5 border_th" >Bowling Points</th> 
                    <th className="roboto-condensed-regular white_color p5 border_th" >Fielding Points</th> 
                    <th className="roboto-condensed-regular white_color p5 border_th" >Other Points</th> 
                    <th className="roboto-condensed-regular white_color p5 border_th" >Total</th>
                   
                </tr>   
            </thead>
    )
}

function DataRankingTableRow({tableRow,indexVal})
{
    
    return(
            <tr>
                <td style={{width:'4%',textAlign:'center'}} className="roboto-condensed-regular white_color p5" >{++indexVal}</td>
                <td style={{width:'23%',textAlign:'left'}} className="roboto-condensed-regular white_color p5" >{tableRow.firstName} {tableRow.lastName}</td>
                <td className="roboto-condensed-regular white_color p5 " >{tableRow.battingPoints}</td>
                <td className="roboto-condensed-regular white_color p5 " >{tableRow.bowlingPoints}</td> 
                <td className="roboto-condensed-regular white_color p5 " >{tableRow.fieldingPoints}</td> 
                <td className="roboto-condensed-regular white_color p5 " >{tableRow.otherPoints}</td> 
                <td className="roboto-condensed-regular white_color p5 " >{tableRow.total}</td>  
            </tr>  
    )
}



export default function RenderRankingDataTable()
{
    useEffect(()=>{fetchRankingStats()});

   
    const [RankingInfo,setRankingInfo]=useState([]);

    async function fetchRankingStatsJSON() {
        const response = await fetch('https://ccapi.cricclubs.com/CCAPI/league/getPlayerRankings?X-Auth-Token=&clubId=63&seriesId=279&teamId=2004&limit=1000');
        const rankingStats = await response.json();
        return rankingStats;

    }

    function fetchRankingStats()
    {
        fetchRankingStatsJSON().then(rankingStats => {
           
            setRankingInfo( rankingStats.data );

        });
    }


    
    
    return(
            <table cellSpacing="0">
                <DataRankingTableHeader />
                
                <tbody>
                    {
                        RankingInfo.map((tableRow,index)=>{
                            return(
                                    <DataRankingTableRow tableRow={tableRow} indexVal={index} />
                            )
                        })
                    }
                </tbody>

            </table>
    )
}