
import SectionTitleEle from './SectionTitleEle';
import 'react-tabs/style/react-tabs.css';

import FixturesAndResults from './FixturesAndResults';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';


function StandingListEle({team})
{
   
    return(
        <div className="SListing_team_ele flex_grid">
            <div className="SListing_name flex_grid">
                <div className="team_ico">
                    <img src={"https://cricclubs.com"+team.logo_file_path} alt="Team Icon" />
                </div>
                <div className="team_name">
                    <p className="roboto-condensed-regular  light_grey p5" >{team.teamName}</p>
                </div> 
            </div>
            <div className="SListing_win">
                <p className="roboto-condensed-bold light_grey p5" >{team.won}</p>
            </div>
            <div className="SListing_lose">
            <p className="roboto-condensed-bold light_grey p5" >{team.lost}</p>
            </div>    
        </div>


    )
}


function LeagueStandings({ seriesId, teamId }) {
    const [latStandings, setLatestStandings] = useState([]);

    useEffect(() => {
        fetchStandings();
    }, [seriesId, teamId]);

    async function fetchstdJSON(seriesId) {
        const response = await fetch(`https://ccapi.cricclubs.com/CCAPI/team/getPointsTable?clubId=63&seriesId=${seriesId}`);
        const standings = await response.json();
        return standings;
    }

    function fetchStandings() {
        fetchstdJSON(seriesId).then(standings => {
            setLatestStandings(standings.data[0].teams);
        });
    }

    return (
        <div className="LT_gridEle LT_league_standings">
            <div className="standings_listing">
                <div className="standings_title">
                    <p className="p4 grey_text roboto-condensed-bold">Standings</p>
                </div>

                <div className="SListing_header flex_grid">
                    <div className="SListing_name">
                        <p className="roboto-condensed-bold light_grey p5">Teams</p>
                    </div>
                    <div className="SListing_win">
                        <p className="roboto-condensed-bold light_grey p5">W</p>
                    </div>
                    <div className="SListing_lose">
                        <p className="roboto-condensed-bold light_grey p5">L</p>
                    </div>
                </div>

                <div className="SListing_listing">
                    {latStandings.slice(0,10).map((standingele, index) => (
                        <StandingListEle key={standingele.team.teamName} team={standingele.team} />
                    ))}
                </div>
            </div>
        </div>
    );
}

function LeagueLogo({ seriesId, teamId, flagImage }) {
    const navigate = useNavigate();

    function handleClick() {
        navigate(`/tournaments/${seriesId}`);
    }

    return (
        <div className="LT_gridEle LT_league_logo flex_grid" style={{ backgroundImage: `url("${flagImage}")` }}>
           
            {/* <div className="league_btn desk_only">
                <Link to={`/2023/midwest/${teamId}`}>
                    <button className="roboto-condensed-bold p2 white_color" onClick={handleClick}>League</button>
                </Link>
            </div> */}
        </div>
    );
}


function LeagueInfo({ seriesId, teamId }) {
    const [matchesPlayed, setMatchesPlayed] = useState(0);
    const [runs, setRuns] = useState(0);
    const [wickets, setWickets] = useState(0);

    useEffect(() => {
        calculateStats();
    }, [seriesId, teamId]);

    async function fetchStatsJSON(seriesId, teamId) {
        const response = await fetch(`https://ccapi.cricclubs.com/CCAPI/stats/getBowlingStats?X-Auth-Token=&clubId=63&seriesId=${seriesId}&teamId=${teamId}`);
        const stats = await response.json();
        return stats;
    }

    function calculateStats() {
        fetchStatsJSON(seriesId, teamId).then(stats => {
            let max_matches = 0,
                l_runs = 0,
                l_wickets = 0;

            stats.data.forEach(stat => {
                l_runs += stat.runs;
                l_wickets += stat.wickets;
                if (max_matches < stat.matches) {
                    max_matches = stat.matches;
                }
            });

            setMatchesPlayed(max_matches);
            setRuns(l_runs);
            setWickets(l_wickets);
        });
    }

    return (
        <div className="LT_gridEle LT_league_info flex_grid">
            <div className="match_info_ele match_played">
                <p className="oswald-regular brand_orange h2">{matchesPlayed}</p>
                <p className="oswald-regular white_color p1">Matches Played</p>
            </div>
            <div className="match_info_ele total_runs">
                <p className="oswald-regular brand_orange h2">{runs}</p>
                <p className="oswald-regular white_color p1">Runs</p>
            </div>
            <div className="match_info_ele total_wickets">
                <p className="oswald-regular brand_orange h2">{wickets}</p>
                <p className="oswald-regular white_color p1">Wickets</p>
            </div>
        </div>
    );
}




export default function Tournaments() {
    const currentYear = new Date().getFullYear().toString();
    const [seriesTeamMappingArr, setSeriesTeamMappingArr] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    const flagImagesInadex = [
        "/images/flags/elite_30_30_flag.png",
        "/images/flags/redball_flag.png",
        "/images/flags/mpl_flag.png"
    ];
    const [flagImage, setFlagImage] = useState(flagImagesInadex[0]);

    useEffect(() => {
        fetchSeriesData();
    }, []);

    const fetchSeriesData = async () => {
        const seriesStats = await fetchAllSeriesJSON();
        const currentYearSeries = seriesStats.data.seriesList.filter((s) => s.year === currentYear);

        let seriesTeamMapping = [];

        for (const series of currentYearSeries) {
            const seriesId = series.seriesID;
            const teamIds = [2240, 2202, 2186]; // Example team IDs

            const teamData = await fetchTeamData(seriesId);

            if (teamData.data.teamsList !== null) {
                teamData.data.teamsList.forEach(group => {
                    group.teams.forEach(team => {
                        if (teamIds.includes(team.teamID)) {
                            seriesTeamMapping.push({ seriesId, teamId: team.teamID });
                        }
                    });
                });
            }
        }

        setSeriesTeamMappingArr(seriesTeamMapping);

        if (seriesTeamMapping.length > 0) {
            const { seriesId, teamId } = seriesTeamMapping[0];
            fetchAllStats(seriesId, teamId);
            setFlagImage(flagImagesInadex[0]); // Set the initial flag image
        }
    };

    async function fetchAllSeriesJSON() {
        const response = await fetch('https://ccapi.cricclubs.com/CCAPI/series/getSeriesList?X-Auth-Token=&clubId=63&limit=');
        const seriesStats = await response.json();
        return seriesStats;
    }

    async function fetchTeamData(seriesId) {
        const response = await fetch(`https://ccapi.cricclubs.com/CCAPI/team/getTeamsList?clubId=63&seriesId=${seriesId}`);
        const teamData = await response.json();
        return teamData;
    }

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) => {
            const newIndex = prevIndex === 0 ? seriesTeamMappingArr.length - 1 : prevIndex - 1;
            const { seriesId, teamId } = seriesTeamMappingArr[newIndex];
            fetchAllStats(seriesId, teamId);
            setFlagImage(flagImagesInadex[newIndex]); // Update the flag image
            return newIndex;
        });
    };

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) => {
            const newIndex = prevIndex === seriesTeamMappingArr.length - 1 ? 0 : prevIndex + 1;
            const { seriesId, teamId } = seriesTeamMappingArr[newIndex];
            fetchAllStats(seriesId, teamId);
            setFlagImage(flagImagesInadex[newIndex]); // Update the flag image
            return newIndex;
        });
    };

    const fetchAllStats = (seriesId, teamId) => {
        // In this example, we simply log the seriesId and teamId.
        console.log(`Fetching stats for Series ID: ${seriesId} and Team ID: ${teamId}`);
    };

    return (
        <section className="base_paddings">
            {seriesTeamMappingArr.length > 0 ? (
                <div className="LT_parent max_content center_aligned">
                    <SectionTitleEle>Tournaments</SectionTitleEle>
                    <div className="LT_container flex_grid">
                        <div className="pagination_home_controls">
                            <img className="phm_left" onClick={handlePrevClick} src="/images/slide_pag_ico.png" alt="PAG ICON" />
                            <img className="phm_right" onClick={handleNextClick} src="/images/slide_pag_ico.png" alt="PAG ICON" />
                        
                        </div>
                        <LeagueLogo 
                            seriesId={seriesTeamMappingArr[currentIndex].seriesId} 
                            teamId={seriesTeamMappingArr[currentIndex].teamId}
                            flagImage={flagImage}
                        />
                        <LeagueInfo seriesId={seriesTeamMappingArr[currentIndex].seriesId} teamId={seriesTeamMappingArr[currentIndex].teamId} />
                        <LeagueStandings seriesId={seriesTeamMappingArr[currentIndex].seriesId} teamId={seriesTeamMappingArr[currentIndex].teamId} />
                        <FixturesAndResults 
                            seriesId={seriesTeamMappingArr[currentIndex].seriesId} 
                            teamId={seriesTeamMappingArr[currentIndex].teamId} 
                            fixtureCount={4} 
                            resultsCount={4} 
                            seriesTeamMappingArr={seriesTeamMappingArr[currentIndex]}
                        />
                    </div>
                   
                </div>
            ) : (
                <div className="loadingAnimation">
                    <img src="/images/loading.gif" alt="loading GIF" />
                </div>
            )}
        </section>
    );
}