function SocialMediaEle({urlPath,imgUrl})
{
    return(
        <div className="social_media_ele">
           <a href={urlPath} target="_blank" rel="noopener noreferrer" > <img src={"/images/social-icons/"+imgUrl} alt="" /></a>
        </div>
    )
}


export default function SocialMediaPanel()
{
    return(
            <div className="social_media_panel ">
                                
                                <SocialMediaEle urlPath="https://www.instagram.com/clubcricketofchicago/" imgUrl={"InstagramIconWhite.svg"} />  
                                <SocialMediaEle urlPath="https://www.facebook.com/ClubCricketOfChicago" imgUrl={"FacebookiconWhite.svg"} />  
                                <SocialMediaEle urlPath="https://www.youtube.com/channel/UClH02lUKHkbEsWTlvBtNCkQ" imgUrl={"YoutubeLogoWhite.svg"} />  
            </div>
    )
}