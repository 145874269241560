import { useState, useEffect } from 'react';

function StandingListEle({team})
{
   
    return(
        <div className="SListing_team_ele flex_grid">
            <div className="SListing_name flex_grid">
                <div className="team_ico">
                    <img src={"https://cricclubs.com"+team.logo_file_path} alt="Team Icon" />
                </div>
                <div className="team_name">
                    <p className="roboto-condensed-regular  light_grey p5" >{team.teamName}</p>
                </div> 
            </div>
            <div className="SListing_win">
                <p className="roboto-condensed-bold light_grey p5" >{team.won}</p>
            </div>
            <div className="SListing_lose">
            <p className="roboto-condensed-bold light_grey p5" >{team.lost}</p>
            </div>    
        </div>


    )
}



export default function LeagueStandings()
{
    useEffect(()=>{fetchStandings()},[]);
    const [latStandings,setLatestStandings] = useState([]);
    async function fetchstdJSON() {
        const response = await fetch('https://ccapi.cricclubs.com/CCAPI/team/getPointsTable?clubId=63&seriesId=279');
        const standings = await response.json();
        return standings;
      }

    function fetchStandings()
    {
        fetchstdJSON().then(standings => {
                
                setLatestStandings(standings.data[0].teams);
                


        });
    }

    return(
            <div className="LT_gridEle LT_league_standings">
                

                <div className="standings_listing">
                    
                    <div className="standings_title">
                        <p className="p4 grey_text roboto-condensed-bold">Standings </p>
                    </div>
                    
                    <div className="SListing_header flex_grid">
                        <div className="SListing_name">
                            <p className="roboto-condensed-bold light_grey p5" >Teams</p>
                        </div>
                        <div className="SListing_win">
                            <p className="roboto-condensed-bold light_grey p5" >W</p>
                        </div>
                        <div className="SListing_lose">
                        <p className="roboto-condensed-bold light_grey p5" >L</p>
                        </div>
                    </div>
                    
                    <div className="SListing_listing ">
                    {latStandings.map((standingele,index) => {
                        
                        return(
                            
                            <StandingListEle key={standingele.team.teamName} team={standingele.team} />
                        )
                        
                    })}
                        
                        
                        
                    </div>
                </div>
            </div>
    )
}