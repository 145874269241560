import HeaderNavPanel from "./components/HeaderNavPanel";
import GroundSlider from "./components/GroundSlider";
import LogoGrid from "./components/LogoGrid";
import Footer from "./components/Footer";
import { Helmet,HelmetProvider  } from "react-helmet-async"


export default function Grounds()
{
    return(
        <>  
            <HelmetProvider>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Cricket Grounds in Chicago | Discover Local Cricket Fields</title>
                
                    <meta name="description" content="Explore the top cricket grounds in Chicago and discover local fields to play the game." />
                    <meta property="og:url" content="/images/og-images/grounds_og_image.jpg" />
                    <meta name="keywords" content="Cricket"></meta>
                </Helmet>
            </HelmetProvider>
            
            
            <HeaderNavPanel />
            <GroundSlider />
            <LogoGrid />
            <Footer />
        </>
    )
}