import Footer from "./components/Footer";
import HeaderNavPanel from "./components/HeaderNavPanel";
import LogoGrid from "./components/LogoGrid";
import { sendForm } from "emailjs-com";
import  { useRef } from 'react';
import { Helmet,HelmetProvider  } from "react-helmet-async"

function BGWithForm()
{
    const form = useRef();
    function clearInputFields()
    {
        document.forms["ju_form"]["full_name"].value = "";
        document.forms["ju_form"]["email"].value = "";
        document.forms["ju_form"]["age"].value = "";
        document.forms["ju_form"]["gender"].value = "";
        document.forms["ju_form"]["club_id"].value = "";
        document.forms["ju_form"]["bio"].value = "";
    }
    const sendEmail = (e) => {
        e.preventDefault();
        console.log("SEND");
        // EMAIL SEND TEST
        sendForm('service_13kiadg', 'template_hr5t6cq', form.current, 'It3y6Vk01CcKyFTFD')
          .then((result) => {
              console.log(result.text);
              if( result.text == "OK")
              {
                clearInputFields();
                document.getElementById("SC_BTN").classList.remove("hidden_SC");
                var x = setInterval(function(){ document.getElementById("SC_BTN").classList.add("hidden_SC"); },5000);
              }
          }, (error) => {
              console.log(error.text);
          });
      };


    return(
            <section className="join_us_container" >
                <div className="base_padding">
                    <div className="join_us_parent max_content center_aligned">
                        <div className="join_us_form">
                            <div className="jUF_title">
                                <h2 className="oswald-regular h1 brand_orange" >Join the Team</h2>
                            </div>
                            <div className="jUF_copy">
                                <p className="roboto-condensed-regular p4">Join Club Cricket of Chicago team and be a part of an exciting journey to success. Compete with and against the best players in the city while developing your skills and building lifelong friendships. Join the team and<br/> <span className="roboto-condensed-bold">LET'S DOMINATE THE PITCH!</span></p>
                            </div>
                            <form ref={form} name="ju_form" onSubmit={sendEmail} >
                                <div className="fullWidth_inpuTag">
                                    <div className="inputTag_holder">
                                        <label className="roboto-condensed-regular p2"  >Full Name</label><br/>
                                        <input type="text" name="full_name" className="roboto-condensed-light p4" required />    
                                    </div>
                                    
                                </div>
                                <div className="fullWidth_inpuTag">
                                    <div className="inputTag_holder">
                                        <label className="roboto-condensed-regular p2"  >Email</label><br/>
                                        <input type="email" name="email" className="roboto-condensed-light p4" required />    
                                    </div>
                                    
                                </div>
                                <div className="fullWidth_inpuTag">
                                    <div className="inputTag_holder">
                                        <label className="roboto-condensed-regular p2"  >Phone Number</label><br/>
                                       
                                        <input className="roboto-condensed-light p4" type="tel" name="telphone" placeholder="888 888 8888" pattern="[0-9]{3} [0-9]{3} [0-9]{4}" maxlength="12"  title="Ten digits code" required/> 
                                    </div>
                                    
                                </div>
                                <div className="fifty_fifty_inputTag flex_grid">
                                    <div className="inputTag_holder">
                                        <label className="roboto-condensed-regular p2"  >Age</label><br/>
                                        <input type="number" name="age" className="roboto-condensed-light p4" required  />    
                                    </div>
                                    <div className="inputTag_holder">
                                        <label className="roboto-condensed-regular p2"  >Gender</label><br/>
                                        <input type="text" name="gender" className="roboto-condensed-light p4" required />  
                                    </div>
                                </div>
                                <div className="fullWidth_inpuTag">
                                    <div className="inputTag_holder">
                                        <label className="roboto-condensed-regular p2"  >CricClub Player ID</label><br/>
                                        <input type="text" name="club_id" className="roboto-condensed-light p4" required />    
                                    </div>
                                    
                                </div>
                                <div className="fullWidth_inpuTag">
                                    <div className="inputTag_holder">
                                        <label className="roboto-condensed-regular p2" >Short Bio</label><br/>
                                        <textarea name="bio" className="roboto-condensed-light p4"></textarea>                                    
                                    </div>
                                </div>
                                <div className="form_submitButton">
                                    <input type="submit" value="Submit" className="roboto-condensed-med p1" />
                                </div>  
                            
                            </form>
                        </div>
                    </div>
                    <div className="success_msg">
                        <div id="SC_BTN" className="SM_container flex_grid hidden_SC">
                            <div className="SM_ico">
                                <img src="/images/submit-successfully.svg" alt="" />
                            </div>
                            <div className="SM_msg">
                                <p className="roboto-condensed-regular p2">Form Submitted</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    )
}
export default function JoinUs()
{
    return(
        <>
            <HelmetProvider>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Join Club Cricket of Chicago | Be Part of Our Team</title>
                
                    <meta name="description" content="Join Club Cricket of Chicago for an exciting journey to success. Compete, develop skills, and build lifelong friendships." />
                    <meta property="og:url" content="/images/og-images/join_us_og_image.jpg" />
                    <meta name="keywords" content="Cricket"></meta>
                </Helmet>
            </HelmetProvider>
            
            <HeaderNavPanel/>
            <BGWithForm />
            <LogoGrid />
            <Footer />
        </>
        
    )
}